<app-header></app-header>

<main class="silka-medium bg-[#EBEBEB] pt-[33px] pb-[92px] md:pb-[82px] px-9">
    <a href="#" class="inline-block mb-[26px] md:mb-9">
      <img src="../../../../assets/icons/arrow-left-black.png" alt="arrow-left-black" />
    </a>
    <div
      class="md:flex md:justify-between md:items-center md:mb-7 md:space-x-9"
    >
      <h2
        class="text-3xl sm:text-[40px] md:text-3xl mb-[60px] sm:mb-[50px] md:mb-0 font-medium"
      >
        Seguridad durante la construcción parte I
      </h2>
      <div
        class="flex space-x-2 w-full text-sm sm:text-2xl md:text-base mb-[70px] md:mb-0 md:max-w-[381px]"
      >
        <div class="text-center bg-white flex-1 py-5">
          <strong class="block">Duración del curso</strong>
          <span>09:00:55</span>
        </div>
        <div class="text-center bg-white flex-1 py-5">
          <strong class="block">Total de lecciones</strong>
          <span>24 lecciones</span>
        </div>
      </div>
    </div>
    <div class="flex flex-col lg:flex-row">
      <!-- Progress -->
      <div
        class="py-[30px] px-4 sm:px-12 lg:px-4 bg-white lg:w-[381px] lg:order-2 lg:ml-9"
      >
        <div
          class="overflow-y-auto max-h-[318px] lg:max-h-[738px] progress pr-3"
        >
          <span class="text-2xl sm:text-[33px] lg:text-2xl inline-block mb-6"
            >Tu Progreso</span
          >
          <!-- Progress Bar -->
          <div
            class="relative w-full h-[78px] lg:h-[29px] bg-[#EBEBEB] flex mb-7 sm:mb-14 lg:mb-7"
          >
            <div class="w-[10px] lg:w-[6px] h-full bg-[#C4C4C4]"></div>
            <div class="w-[20%] bg-red-600 h-full"></div>
            <span
              class="absolute right-4 top-5 lg:top-[2px] text-3xl lg:text-base z-10"
              >20%</span
            >
          </div>
          <!-- End progress bar -->
          <span class="text-2xl sm:text-[33px] lg:text-2xl">Contenidos</span>
          <ul class="mt-5 steps__list">
            <li class="w-full flex pb-6 steps__item relative space-x-4">
              <div
                class="bg-red-600 text-white h-12 w-12 lg:h-9 lg:w-9 text-center rounded-full steps__count relative"
              >
                <span
                  class="text-[36px] font-bold leading-[1.4] lg:text-[22px] lg:leading-[1.6]"
                  >1</span
                >
              </div>
              <div class="flex-1 pt-2 lg:pt-1">
                <a
                  class="block text-red-600 font-bold text-base sm:text-2xl lg:text-lg mb-3"
                  >Introducción</a
                >
                <span class="text-sm">Duración: 00:00:55</span>
              </div>
              <div class="grid place-items-center">
                <img
                  src="../../../../assets/icons/arrow-down-black.png"
                  alt="arrow-down"
                  class="block lg:hidden"
                />
                <img
                  src="../../../../assets/icons/arrow-down-black-smaller.png"
                  alt="arrow-down"
                  class="hidden lg:block"
                />
              </div>
            </li>
            <li class="w-full flex pb-6 steps__item relative space-x-4">
              <div
                class="bg-[#CDCDCD] text-black h-12 w-12 lg:h-9 lg:w-9 text-center rounded-full steps__count relative"
              >
                <span
                  class="text-[36px] font-bold leading-[1.4] lg:text-[22px] lg:leading-[1.6]"
                  >2</span
                >
              </div>
              <div class="flex-1 pt-2 lg:pt-1">
                <a
                  class="block font-bold text-base sm:text-2xl lg:text-lg mb-3"
                  >¿Qué es la cimentación?</a
                >
                <span class="text-sm">Duración: 00:00:55</span>
              </div>
              <div class="grid place-items-center">
                <img
                  src="../../../../assets/icons/arrow-down-black.png"
                  alt="arrow-down"
                  class="block lg:hidden"
                />
                <img
                  src="../../../../assets/icons/arrow-down-black-smaller.png"
                  alt="arrow-down"
                  class="hidden lg:block"
                />
              </div>
            </li>
            <li class="w-full flex pb-6 steps__item relative space-x-4">
              <div
                class="bg-[#CDCDCD] text-black h-12 w-12 lg:h-9 lg:w-9 text-center rounded-full steps__count relative"
              >
                <span
                  class="text-[36px] font-bold leading-[1.4] lg:text-[22px] lg:leading-[1.6]"
                  >3</span
                >
              </div>
              <div class="flex-1 pt-2 lg:pt-1">
                <a
                  class="block font-bold text-base sm:text-2xl lg:text-lg mb-3"
                  >¿Cuál es la función principal?</a
                >
                <span class="text-sm">Duración: 00:00:55</span>
              </div>
              <div class="grid place-items-center">
                <img
                  src="../../../../assets/icons/arrow-down-black.png"
                  alt="arrow-down"
                  class="block lg:hidden"
                />
                <img
                  src="../../../../assets/icons/arrow-down-black-smaller.png"
                  alt="arrow-down"
                  class="hidden lg:block"
                />
              </div>
            </li>
            <li class="w-full flex pb-6 steps__item relative space-x-4">
              <div
                class="bg-[#CDCDCD] text-black h-12 w-12 lg:h-9 lg:w-9 text-center rounded-full steps__count relative"
              >
                <span
                  class="text-[36px] font-bold leading-[1.4] lg:text-[22px] lg:leading-[1.6]"
                  >4</span
                >
              </div>
              <div class="flex-1 pt-2 lg:pt-1">
                <a
                  class="block font-bold text-base sm:text-2xl lg:text-lg mb-3"
                  >¿Cuál es la función principal?</a
                >
                <span class="text-sm">Duración: 00:00:55</span>
              </div>
              <div class="grid place-items-center">
                <img
                  src="../../../../assets/icons/arrow-down-black.png"
                  alt="arrow-down"
                  class="block lg:hidden"
                />
                <img
                  src="../../../../assets/icons/arrow-down-black-smaller.png"
                  alt="arrow-down"
                  class="hidden lg:block"
                />
              </div>
            </li>
            <li class="w-full flex pb-6 steps__item relative space-x-4">
              <div
                class="bg-[#CDCDCD] text-black h-12 w-12 lg:h-9 lg:w-9 text-center rounded-full steps__count relative"
              >
                <span
                  class="text-[36px] font-bold leading-[1.4] lg:text-[22px] lg:leading-[1.6]"
                  >5</span
                >
              </div>
              <div class="flex-1 pt-2 lg:pt-1">
                <a
                  class="block font-bold text-base sm:text-2xl lg:text-lg mb-3"
                  >¿Cuál es la función principal?</a
                >
                <span class="text-sm">Duración: 00:00:55</span>
              </div>
              <div class="grid place-items-center">
                <img
                  src="../../../../assets/icons/arrow-down-black.png"
                  alt="arrow-down"
                  class="block lg:hidden"
                />
                <img
                  src="../../../../assets/icons/arrow-down-black-smaller.png"
                  alt="arrow-down"
                  class="hidden lg:block"
                />
              </div>
            </li>
            <li class="w-full flex pb-6 steps__item relative space-x-4">
              <div
                class="bg-[#CDCDCD] text-black h-12 w-12 lg:h-9 lg:w-9 text-center rounded-full steps__count relative"
              >
                <span
                  class="text-[36px] font-bold leading-[1.4] lg:text-[22px] lg:leading-[1.6]"
                  >6</span
                >
              </div>
              <div class="flex-1 pt-2 lg:pt-1">
                <a
                  class="block font-bold text-base sm:text-2xl lg:text-lg mb-3"
                  >¿Cuál es la función principal?</a
                >
                <span class="text-sm">Duración: 00:00:55</span>
              </div>
              <div class="grid place-items-center">
                <img
                  src="../../../../assets/icons/arrow-down-black.png"
                  alt="arrow-down"
                  class="block lg:hidden"
                />
                <img
                  src="../../../../assets/icons/arrow-down-black-smaller.png"
                  alt="arrow-down"
                  class="hidden lg:block"
                />
              </div>
            </li>
            <li class="w-full flex pb-6 steps__item relative space-x-4">
              <div
                class="bg-[#CDCDCD] text-black h-12 w-12 lg:h-9 lg:w-9 text-center rounded-full steps__count relative"
              >
                <span
                  class="text-[36px] font-bold leading-[1.4] lg:text-[22px] lg:leading-[1.6]"
                  >7</span
                >
              </div>
              <div class="flex-1 pt-2 lg:pt-1">
                <a
                  class="block font-bold text-base sm:text-2xl lg:text-lg mb-3"
                  >¿Cuál es la función principal?</a
                >
                <span class="text-sm">Duración: 00:00:55</span>
              </div>
              <div class="grid place-items-center">
                <img
                  src="../../../../assets/icons/arrow-down-black.png"
                  alt="arrow-down"
                  class="block lg:hidden"
                />
                <img
                  src="../../../../assets/icons/arrow-down-black-smaller.png"
                  alt="arrow-down"
                  class="hidden lg:block"
                />
              </div>
            </li>
          </ul>
        </div>
      </div>
      <!-- End progress -->
      <!-- Video and description -->
      <div class="md:flex-1 md:order-1">
        <div class="relative">
          <div class="preview_video bg-no-repeat bg-cover min-h-[460px] my-14 lg:mt-0 lg:mb-7 px-8 flex items-center"></div>
          <div class="absolute title text-2xl sm:text-[36px] lg:text-4xl text-white font-medium">
            <span class="block">1.</span>
            <span class="block">Introducci&oacute;n</span>
          </div>
          <button class="absolute bottom-[40px] right-[40px]">
            <img src="../../../../assets/icons/play-bigger.png" alt="play" />
          </button>
        </div>
        
          
          
        
        <div class="flex justify-end">
          <button
            class="block flex w-1/2 md:w-[280px] lg:w-[200px] rounded-[10px] bg-red-600 py-5 justify-between text-lg sm:text-3xl lg:text-sm px-5 sm:pl-[50px] sm:pr-9 md:py-2 lg:px-5 text-white items-center"
          >
            <span
              >Siguiente
              <span class="hidden lg:inline-block ml-[1px]"
                >lección</span
              ></span
            >
            <img
              src="../../../../assets/icons/arrow-white-btn-smaller.png"
              alt="arrow-white-btn"
              class="block sm:hidden lg:block"
            />
            <img
              src="../../../../assets/svg/arrow-white-btn.svg"
              alt="arrow-white-btn"
              class="hidden sm:block lg:hidden"
            />
          </button>
        </div>
        <div class="mt-[67px] space-y-7 lg:mt-5">
          <h3 class="text-2xl sm:text-3xl lg:text-xl font-bold">
            1. Introducción
          </h3>
          <p>
            Introducción Nuestra responsabilidad como empresa referente en el
            sector constructivo es velar en todo momento por la seguridad,
            integridad y salud de los trabajadores de construcción. Por eso,
            ponemos a tu disposición la normativa vigente con los nuevos
            protocolos sanitarios que deben cumplirse de manera obligatoria en
            todos los proyectos de construcción. De esta forma podrás vigilar,
            prevenir y controlar la propagación del Covid-19 en tus obras de
            construcción.
          </p>
        </div>
      </div>
    </div>
    <!-- End video and description -->
  </main>

<app-otros-cursos></app-otros-cursos>
<app-footer></app-footer>

