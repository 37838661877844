<section class="section section__404">
  <div class="container">
    <div class="h-[135px] grid place-items-center col-span-full md:h-auto md:col-span-1 head">
      <div class="logo-container">
        <div class="logo">
          <div class="unacem">
            <img src="../../../assets/svg/logo/imagen.svg" style="width: 20px; "/>
            <img src="../../../assets/svg/logo/U-unacem.svg" style="width: 15px; margin-left: 2px;"/>
            <img src="../../../assets/svg/logo/N-unacem.svg" style="width: 15px;"/>
            <img src="../../../assets/svg/logo/A-unacem.svg" style="width: 15px;"/>
            <img src="../../../assets/svg/logo/C-unacem.svg" style="width: 15px;"/>
            <img src="../../../assets/svg/logo/E-unacem.svg" style="width: 15px;"/>
            <img src="../../../assets/svg/logo/M-unacem.svg" style="width: 15px;"/>
          </div>
          <div class="cantera" style="display: flex; padding-top: 3px;">
            <img src="../../../assets/svg/logo/C-cantera.svg" style="width: 31px; height: 30px; margin-right: -1px;"/>
            <img src="../../../assets/svg/logo/A-cantera.svg" style="width: 32px; height: 30px; padding-right: 1.1px;"/>
            <img src="../../../assets/svg/logo/N-cantera.svg" style="width: 30px; height: 30px; padding-right: 1.5px;"/>
            <img src="../../../assets/svg/logo/T-cantera.svg" style="width: 30px; height: 30px; padding-right: 1px;"/>
            <img src="../../../assets/svg/logo/E-cantera.svg" style="width: 29px; height: 29px; padding-right: 1.5px; margin-top: 0.5px;"/>
            <img src="../../../assets/svg/logo/R-cantera.svg" style="width: 29px; height: 30px; "/>
            <img src="../../../assets/svg/logo/A-cantera.svg" style="width: 32px; height: 30px; padding-left: -0.5px;"/>
          </div>
        </div>
      </div>
      <div class="relative py-[20px] text-white text-center">
        <div class="py-[20px]"></div>
        <h1>Page Not Found</h1>
        <h2 class="text-[26px] mb-[15px] sm:text-[40px] md:text-[100px] font-bold m-0">Error 404</h2>
        <p class="md:text-[26px]">No podemos encontrar la página que estás buscando.</p>
      </div>
    </div>
  </div>
</section>