<!-- <h1><b>MURO DE CONTENCION</b></h1> -->
<div class="md:grid md:grid-cols-2 md:gap-[35px]">
    <div class="content-detail px-[20px] pt-[45px] bg-white mt-[45px] sm:px-[25px] sm:pt-[25px]">
        <form #form="ngForm" class="form-detail-4 relative mb-[160px] lg:mb-[120px]">
            <div class="design border-b-[1px] border-black">
                <p class="text-[28px] lg:text-[14px] lg:mb-[10px]">Forma de colocar el ladrillo</p>
                <div class="mb-[50px] lg:grid lg:grid-cols-3 lg:bg-[#EBEBEB] lg:py-[10px] lg:px-[20px] lg:mb-[30px]">
                    <div *ngFor="let opt of opciones" class="radio-item w-full mt-[39px] lg:mt-0">
                        <input (click)="cambiar(opt)" class="rd-btn" type="radio" id="{{opt}}" name="option" value="{{opt}}" />
                        <label class="text-[26px] lg:text-[14px]" style="cursor: pointer;" for="{{opt}}"><span>{{opt}}</span></label>
                    </div>
                </div>
            </div>
            <div class="measures mt-[30px] pb-[50px] border-b-[1px] border-black lg:mt-[35px] lg:mb-[30px] lg:grid lg:grid-cols-3 lg:gap-[15px] lg:bg-[#EBEBEB] lg:py-[9px] lg:px-[20px] lg:border-0">
                <div class="mb-[36px] lg:mb-0 lg:flex lg:items-center ">
                    <span class="text-[26px] lg:text-[14px] lg:mr-[8px]">Largo</span>
                    <input [(ngModel)]="largo" (ngModelChange)="cambiar(valor)" class="bg-[#C4C4C4] text-[30px] px-[25px] py-[20px] w-full lg:text-[14px] lg:bg-white lg:px-[10px] lg:py-[5px] lg:max-w-[80px]" type="number" id="largo" name="largo" required minlength="4" maxlength="8" size="10">
                </div>
                <div class="mb-[36px] lg:mb-0 lg:flex lg:items-center ">
                    <span class="text-[26px] lg:text-[14px] lg:mr-[8px]">Ancho</span>
                    <input  [(ngModel)]="ancho" (ngModelChange)="cambiar(valor)" class="bg-[#C4C4C4] text-[30px] px-[25px] py-[20px] w-full lg:text-[14px] lg:bg-white lg:px-[10px] lg:py-[5px] lg:max-w-[80px]" type="number" id="width" name="width" required minlength="4" maxlength="8" size="10">
                </div>
            </div>
            <button id="btn-resultados" class="bg-[#FF0000] text-white text-[30px] bottom-[-120px] rounded-[10px] absolute left-0 right-0 mx-auto w-fit px-[50px] py-[15px] sm:hidden">Resultados <i class="fa fa-angle-right ml-[20px]"></i></button>
        </form>
    </div>

    <!-- RESULTADOS -->
    <div class="content-results px-[40px] py-[30px] px-[20px] py-[45px] bg-white mt-[70px] sm:px-[25px] lg:mt-[45px]">
        <p class="text-[30px] border-b-[1px] border-black mb-[20px] lg:text-[18px]">Resultados</p>
        <table class="table-results mb-[80px] w-full">
            <tr class="text-[12px] sm:text-[14px]">
              <td class="w-1/2">Cantidad de ladrillo</td>
              <td class="w-1/2 font-medium">{{ result_ladrillo | number }}</td>
            </tr>
            <tr class="text-[12px] sm:text-[14px]">
                <td class="w-1/2">Bolsas de cemento</td>
                <td class="w-1/2 font-medium">{{ result_cemento | number }}</td>
            </tr>
            <tr class="text-[12px] sm:text-[14px]">
                <td class="w-1/2">Arena gruesa(m3)</td>
                <td class="w-1/2 font-medium">{{ result_arena | number }}</td>
            </tr>
        </table>
        <div class="border-t-[1px] border-black pt-[25px] md:flex">
            <div class="md:w-1/2">
                <p class="text-[16px]">Contacta y cotiza tus materiales de construcción en:</p>
            </div>
            <div class="mt-[25px] md:w-1/2 md:mt-0">
                <a class="btn-progresol block text-center py-[11px] text-[20px] text-white bg-black rounded-[10px] max-w-[225px] md:ml-auto md:mr-0 md:text-[14px] md:max-w-[200px]" href="#">Progresol</a>
            </div>
        </div>  
    </div>
</div>
