import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanLoad,
  Route,
  Router,
  RouterStateSnapshot,
  UrlSegment,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthorizationGuard implements CanActivate, CanLoad {
  constructor(private router: Router) {}
  canActivate(): boolean {
    //console.log( 'autorizacion canactivate ', 'token ', localStorage.getItem('token') );
    let c = localStorage.getItem('token') ? true : false;
    //console.log(c);

    if (c == true) {
      //console.log('existe token');
    } else {
      localStorage.clear();
      //this.router.navigateByUrl('/public2/constructor');
      this.router.navigateByUrl('/constructor');
    }
    return c;
  }
  canLoad(): boolean {
    //console.log( 'autorizacion canLoad ', 'token ', localStorage.getItem('token') );
    let c = localStorage.getItem('token') ? true : false;
    //console.log(c);

    if (c == true) {
      //console.log('existe token');
    } else {
      localStorage.clear();
      //this.router.navigateByUrl('/public2/constructor');
      this.router.navigateByUrl('/constructor');
    }
    return c;
  }

  private handleErrorGoHome() {
    //this.router.navigateByUrl('/public2/constructor');
    this.router.navigateByUrl('/constructor');
    return false;
  }
}
