import { ICursosItem } from "./ICurso-item.metadata";

export const CURSOS_DATA_ITEMS : ICursosItem[] = [
    {
        id: 1,
        titulo: "INTRODUCCIÓN A LA CONSTRUCCIÓN DE VIGAS Y LLOSAS",
        subtitulo: "NORMATIVA VIGENTE PARA LA REANUDACIÓN DE ACTIVIDADES FRENTE A LA PROPAGACIÓN DE COVID-19",
        imagen: "../../../assets/img/cursos/1.png",
        descripcion: "Conoce la normativa vigente con los nuevos protocolos sanitarios que deben cumplirse de manera obligatoria en todos los...",
    },
    {
        id: 2,
        titulo: "INTRODUCCIÓN A LA CONSTRUCCIÓN DE COLUMNAS",
        subtitulo: "ELABORACIÓN DE PRESUPUESTOS DE OBRAS DE CONSTRUCCIÓN",
        imagen: "../../../assets/img/cursos/2.png",
        descripcion: "El presupuesto de construcción es un documento que resume el cálculo anticipado del costo de una obra; y es importante...",
    },
    {
        id: 3,
        titulo: "IMPORTANCIA DE UNA BUENA DOSIFICACIÓN DE CONCRETO",
        subtitulo: "¿CÓMO MEJORAR LA PRODUCTIVIDAD EN LA CONSTRUCCIÓN?",
        imagen: "../../../assets/img/cursos/3.png",
        descripcion: "Aprende a planificar los tiempos, optimizar los costos y hacer tus proyectos de construcción más rentables. En este curso...",
    },
    {
        id: 4,
        titulo: "INTRODUCCIÓN A LA CONSTRUCCIÓN DE MUROS DE LADRILLOS",
        subtitulo: "LOS SEIS PRINCIPALES RIESGOS DE CONSTRUCCIÓN Y CÓMO REDUCIRLOS",
        imagen: "../../../assets/img/cursos/4.png",
        descripcion: "Durante tus proyectos de construcción es posible que te encuentres con una serie de desafíos sobre calidad...",
    },
    {
        id: 5,
        titulo: "LECTURA E INTERPRETACIÓN DE PLANOS",
        subtitulo: "TENDENCIAS TECNOLÓGICAS PARA CONSTRUIR MEJOR",
        imagen: "../../../assets/img/cursos/5.png",
        descripcion: "Para ser competitivo en el rubro de la construcción es importante evaluar el nivel tecnológico actual, es así que se puede...",
    },
    {
        id: 6,
        titulo: "INTRODUCCIÓN A LA CONSTRUCCIÓN DE CIMENTACIONES",
        subtitulo: "PORTAFOLIO DE CEMENTOS UNACEM",
        imagen: "../../../assets/img/cursos/6.png",
        descripcion: "Ponemos a tu disposición toda la información relacionada a la clasificación de los cementos y el detalle del portafolio que...",
    },
    {
      id: 7,
      titulo: "INTRODUCCIÓN A LA CONSTRUCCIÓN DE VIGAS Y LLOSAS II",
      subtitulo: "NORMATIVA VIGENTE PARA LA REANUDACIÓN DE ACTIVIDADES FRENTE A LA PROPAGACIÓN DE COVID-19",
      imagen: "../../../assets/img/cursos/1.png",
      descripcion: "Conoce la normativa vigente con los nuevos protocolos sanitarios que deben cumplirse de manera obligatoria en todos los...",
  },
  {
      id: 8,
      titulo: "INTRODUCCIÓN A LA CONSTRUCCIÓN DE COLUMNAS II",
      subtitulo: "ELABORACIÓN DE PRESUPUESTOS DE OBRAS DE CONSTRUCCIÓN",
      imagen: "../../../assets/img/cursos/2.png",
      descripcion: "El presupuesto de construcción es un documento que resume el cálculo anticipado del costo de una obra; y es importante...",
  },
  {
      id: 9,
      titulo: "IMPORTANCIA DE UNA BUENA DOSIFICACIÓN DE CONCRETO II",
      subtitulo: "¿CÓMO MEJORAR LA PRODUCTIVIDAD EN LA CONSTRUCCIÓN?",
      imagen: "../../../assets/img/cursos/3.png",
      descripcion: "Aprende a planificar los tiempos, optimizar los costos y hacer tus proyectos de construcción más rentables. En este curso...",
  },
  {
      id: 10,
      titulo: "INTRODUCCIÓN A LA CONSTRUCCIÓN DE MUROS DE LADRILLOS II",
      subtitulo: "LOS SEIS PRINCIPALES RIESGOS DE CONSTRUCCIÓN Y CÓMO REDUCIRLOS",
      imagen: "../../../assets/img/cursos/4.png",
      descripcion: "Durante tus proyectos de construcción es posible que te encuentres con una serie de desafíos sobre calidad...",
  },
  {
      id: 11,
      titulo: "LECTURA E INTERPRETACIÓN DE PLANOS II",
      subtitulo: "TENDENCIAS TECNOLÓGICAS PARA CONSTRUIR MEJOR",
      imagen: "../../../assets/img/cursos/5.png",
      descripcion: "Para ser competitivo en el rubro de la construcción es importante evaluar el nivel tecnológico actual, es así que se puede...",
  },
  {
      id: 12,
      titulo: "INTRODUCCIÓN A LA CONSTRUCCIÓN DE CIMENTACIONES II",
      subtitulo: "PORTAFOLIO DE CEMENTOS UNACEM",
      imagen: "../../../assets/img/cursos/6.png",
      descripcion: "Ponemos a tu disposición toda la información relacionada a la clasificación de los cementos y el detalle del portafolio que...",
  },
  {
    id: 13,
    titulo: "INTRODUCCIÓN A LA CONSTRUCCIÓN DE VIGAS Y LLOSAS III",
    subtitulo: "NORMATIVA VIGENTE PARA LA REANUDACIÓN DE ACTIVIDADES FRENTE A LA PROPAGACIÓN DE COVID-19",
    imagen: "../../../assets/img/cursos/1.png",
    descripcion: "Conoce la normativa vigente con los nuevos protocolos sanitarios que deben cumplirse de manera obligatoria en todos los...",
},
{
    id: 14,
    titulo: "INTRODUCCIÓN A LA CONSTRUCCIÓN DE COLUMNAS III",
    subtitulo: "ELABORACIÓN DE PRESUPUESTOS DE OBRAS DE CONSTRUCCIÓN",
    imagen: "../../../assets/img/cursos/2.png",
    descripcion: "El presupuesto de construcción es un documento que resume el cálculo anticipado del costo de una obra; y es importante...",
},
{
    id: 15,
    titulo: "IMPORTANCIA DE UNA BUENA DOSIFICACIÓN DE CONCRETO III",
    subtitulo: "¿CÓMO MEJORAR LA PRODUCTIVIDAD EN LA CONSTRUCCIÓN?",
    imagen: "../../../assets/img/cursos/3.png",
    descripcion: "Aprende a planificar los tiempos, optimizar los costos y hacer tus proyectos de construcción más rentables. En este curso...",
},
{
    id: 16,
    titulo: "INTRODUCCIÓN A LA CONSTRUCCIÓN DE MUROS DE LADRILLOS III",
    subtitulo: "LOS SEIS PRINCIPALES RIESGOS DE CONSTRUCCIÓN Y CÓMO REDUCIRLOS",
    imagen: "../../../assets/img/cursos/4.png",
    descripcion: "Durante tus proyectos de construcción es posible que te encuentres con una serie de desafíos sobre calidad...",
},
{
    id: 17,
    titulo: "LECTURA E INTERPRETACIÓN DE PLANOS III",
    subtitulo: "TENDENCIAS TECNOLÓGICAS PARA CONSTRUIR MEJOR",
    imagen: "../../../assets/img/cursos/5.png",
    descripcion: "Para ser competitivo en el rubro de la construcción es importante evaluar el nivel tecnológico actual, es así que se puede...",
},
{
    id: 18,
    titulo: "INTRODUCCIÓN A LA CONSTRUCCIÓN DE CIMENTACIONES III",
    subtitulo: "PORTAFOLIO DE CEMENTOS UNACEM",
    imagen: "../../../assets/img/cursos/6.png",
    descripcion: "Ponemos a tu disposición toda la información relacionada a la clasificación de los cementos y el detalle del portafolio que...",
}
]