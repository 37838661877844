import { Component, OnInit } from '@angular/core';
import { CookieService } from '@app/services/cookie.service';
import { GoogleTagManagerService } from 'angular-google-tag-manager';

@Component({
  selector: 'app-cookie-notice',
  templateUrl: './cookie-notice.component.html',
  styleUrls: ['./cookie-notice.component.css']
})
export class CookieNoticeComponent implements OnInit {
  showNotice = true;
  activeTab: string = 'statementCookies';
  accordionOpen: boolean = false;

  constructor(
    private cookieService: CookieService,
    private gtmService: GoogleTagManagerService
  ) {}

  ngOnInit() {
    const userConsent = this.cookieService.getCookie('Cookie_concept');
    if (!userConsent) {
      this.showNotice = true;
      document.body.style.overflow = 'hidden';
    } else {
      this.showNotice = false;
      document.body.style.overflow = 'initial';
    }
  }

  selectTab(tab: string) {
    this.activeTab = tab;
  }

  toggleAccordion() {
    this.accordionOpen = !this.accordionOpen;
  }

  allowCookies() {
    this.cookieService.setCookie('Cookie_concept', 'Allowed', 730);
    this.showNotice = false;
    document.body.style.overflow = 'initial';
    this.activateTracking();
  }

  denyCookies() {
    this.cookieService.setCookie('Cookie_concept', 'Denied', 0);
    this.showNotice = false;
    document.body.style.overflow = 'initial';
  }

  activateTracking() {
    this.gtmService.pushTag({
      event: 'aceptar-cookies'
    });
  }
}
