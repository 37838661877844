import { IGraduados } from './graduados-item.medatada';

export const GRADUADOS_DATA_ITEMS: IGraduados[] = [
  {
    id: 1,
    titulo: 'Testimonio 1',
    subtitulo: 'Testimonio 1',
    imagen: '../../../assets/img/graduados1.png',
    descripcion: '',
  },
  {
    id: 2,
    titulo: 'Testimonio 2',
    subtitulo: 'Testimonio 2',
    imagen: '../../../assets/img/graduados2.png',
    descripcion: '',
  },
  {
    id: 3,
    titulo: 'Testimonio 3',
    subtitulo: 'Testimonio 3',
    imagen: '../../../assets/img/graduados3.png',
    descripcion: '',
  },
];
