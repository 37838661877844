import { Component, Input, OnInit } from '@angular/core';
import { IFacebookLive } from '../lista-facebook-live/IFacebook-live-item.medatada';

@Component({
  selector: 'app-webinars',
  templateUrl: './webinars.component.html',
  styleUrls: ['./webinars.component.scss']
})
export class WebinarsComponent implements OnInit {

  @Input() items: IFacebookLive[] = [];

  paginaActual: number = 1;
  numItemsAmostrar: number = 6;

  totalDePaginas: number = 0;

  constructor() { }

  ngOnInit(): void {
  }

}
