import { Component, HostListener, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IGraduados } from './graduados-item.medatada';

@Component({
  selector: 'app-lista-graduados',
  templateUrl: './lista-graduados.component.html',
  styleUrls: ['./lista-graduados.component.scss'],
})

@HostListener('window:scroll', [])

export class ListaGraduadosComponent implements OnInit {
  @Input() items: IGraduados[] = [];

  paginaActual: number = 1;
  numItemsAmostrar: number = 3;

  totalDePaginas: number = 0;

 

  constructor(private router: Router) {}

  ngOnInit(): void {
    
  }

  openLive(idVideo: number){
    this.router.navigate([`/usuario/graduados-open/${idVideo}`]);
    this.scrollToTop();
  }

  scrollToTop() { 
    (function smoothscroll() 
    { var currentScroll = document.documentElement.scrollTop || document.body.scrollTop; 
      if (currentScroll > 0) 
      {
        window.requestAnimationFrame(smoothscroll);
        window.scrollTo(0, currentScroll - (currentScroll / 5));
      }
    })();
  }

}
