import { Component, HostListener, OnInit, } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators, AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '@app/services/auth.service';
import { SwitchService } from '@app/services/switch.service';
import { environment } from '../../../../environments/environment';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-resetpass',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})

export class ResetPassComponent implements OnInit {

  minHeight!: number;
  formResspassword! : UntypedFormGroup;
  user_key!: string;
  user_id!: string;

  constructor(  
    private router: Router,
    private authService: AuthService, 
    private fb: UntypedFormBuilder,
  ) { 
    this.createForm();
  }

  createForm (){
    this.formResspassword = this.fb.group(
      {
        password1: ['', [
          Validators.required,
          this.validations(),
          Validators.minLength(8),
          //this.matchValidator('password2', true)
        ] ],
        password2: ['', [
          Validators.required, 
          this.matchValidator('password1')] 
        ],
      }
    )
  }

  validations(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;
      const errors: ValidationErrors = {};

      if (!/[A-Z]/.test(value)) {
        errors['mayusError'] = true;
      }
      if (!/[a-z]/.test(value)) {
        errors['minusError'] = true;
      }
      if (!/[\d]/.test(value)) {
        errors['numberError'] = true;
      }
      /* if (!/[@#$%^&+=!]/.test(value)) {
        errors['symbolError'] = true;
      } */

      return Object.keys(errors).length > 0 ? errors : null;
    };
  }

  matchValidator( matchTo: string, reverse?: boolean ) : ValidatorFn{
    return (control: AbstractControl):  ValidationErrors | null => {
      if (control.parent && reverse) {
        const c = (control.parent?.controls as any)[matchTo]  as AbstractControl;
        if (c) {
          c.updateValueAndValidity();
        }
        return null;
      }
      return !!control.parent && !!control.parent.value && control.value ===  (control.parent?.controls as any)[matchTo].value ? null : { matching: true };
    };
  }

  ngOnInit(): void {
    
    const url = this.router.parseUrl(this.router.url)
    this.user_key = url.queryParams['key'];
    if( this.user_key ){
      this.authService.validKey(this.user_key).subscribe(
        (resp :any ) =>{
          //console.log(resp)
          if( resp.success == true){
            this.user_id = resp.user_id
          }

          if( resp.success == false ){
            this.user_key = resp.message;
            Swal.fire(resp.message, '', 'error');
            this.formResspassword.disable();
            this.user_key = '';
          }
        },
        (errors :any) => {
          console.log(errors)
          if(errors.error.success == false && errors.error.message){
            Swal.fire(errors.error.message, '', 'error');
            this.formResspassword.disable();
            this.user_key = '';
          }
        }
      )
    }
  }

  onSubmit() {
    const formData = this.formResspassword.value;
    const pass = formData.password1;
    const user_id = this.user_id;
    if(this.formResspassword.valid){
      this.authService.changePassword( user_id, pass ).subscribe(
        (resp :any ) =>{
          if( resp.success == true){
            this.formResspassword.reset();
            this.router.navigate(['/constructor']);
            //this.user_key = resp.message;
            //this.user_key = '';
            Swal.fire(resp.message, '', 'success');
          }
          if( resp.success == false ){
            Swal.fire(resp.message, '', 'error');
          }
        }
      )
    }
  }

}

