<app-header></app-header>

<main>

  <div class="relative">
    <div
      class="banner bg_banner_reclamaciones bg-cover bg-no-repeat h-[330px] grid items-center"
    ></div>
    <div class="title text-center text-white">
      <h1 class="">Libro de Reclamaciones</h1>
    </div>
    <img
      class="absolute top-[30px] left-[43px]"
      src="../../../../assets/icons/arrow-left.png"
      alt="flecha"
    />
  </div>

<div class="container">
  <div class="company-info">
      <div class="company-info-header">
        <div class="left">
          <p>RAZON SOCIAL: <span>UNACEM Perú S.A.</span></p>
          <p>RUC: <span>20608552171</span></p>
          <p>DIRECCION: <span>Atocongo 2440, Villa María del Triunfo 15822</span></p>
        </div>
        <div class="right">
          <p>Fecha: <span>{{ currentDate | date:'dd/MM/yyyy' }}</span></p>
        </div>
      </div>
  </div>
  <form [formGroup]="reclamationForm" (ngSubmit)="onSubmit()">
    <div class="section">
      <h2>I. DATOS DEL CONSUMIDOR RECLAMANTE</h2>
      <table class="form-table" formGroupName="consumer">
        <tr>
          <td><label for="dniConsumer">DNI:</label></td>
          <td colspan="2"><label for="nameConsumer">Nombres y Apellidos:</label></td>
        </tr>
        <tr>
          <td><input id="dniConsumer" formControlName="dni" type="text" [ngClass]="{'is-invalid': isFieldInvalid('consumer', 'dni')}"></td>
          <td colspan="2"><input id="nameConsumer" formControlName="name" type="text"  [ngClass]="{'is-invalid': isFieldInvalid('consumer', 'name')}"></td>
        </tr>
        <tr>
          <td colspan="3"><label for="addressConsumer">Domicilio:</label></td>
        </tr>
        <tr>
          <td colspan="3"><input id="addressConsumer" formControlName="address" type="text"  [ngClass]="{'is-invalid': isFieldInvalid('consumer', 'address')}"></td>
        </tr>
        <tr>
          <td><label for="emailConsumer">Correo Electrónico:</label></td>
          <td><label for="phoneConsumer">Teléfono:</label></td>
          <td><label for="mobileConsumer">Celular:</label></td>
        </tr>
        <tr>
          <td><input id="emailConsumer" formControlName="email" type="email"  [ngClass]="{'is-invalid': isFieldInvalid('consumer', 'email')}"></td>
          <td><input id="phoneConsumer" formControlName="phone" type="text" [ngClass]="{'is-invalid': isFieldInvalid('consumer', 'phone')}"></td>
          <td><input id="mobileConsumer" formControlName="mobile" type="text" [ngClass]="{'is-invalid': isFieldInvalid('consumer', 'mobile')}"></td>
        </tr>
      </table>
    </div>

    <div class="section">
      <h2>II. DATOS DEL PADRE DE FAMILIA (Completar en caso del reclamante sea menor de edad)</h2>
      <table class="form-table" formGroupName="parent">
        <tr>
          <td><label for="dniParent">DNI:</label></td>
          <td colspan="2"><label for="nameParent">Nombres y Apellidos:</label></td>
        </tr>
        <tr>
          <td><input id="dniParent" formControlName="dni" type="text"></td>
          <td colspan="2"><input id="nameParent" formControlName="name" type="text"></td>
        </tr>
        <tr>
          <td colspan="3"><label for="addressParent">Domicilio:</label></td>
        </tr>
        <tr>
          <td colspan="3"><input id="addressParent" formControlName="address" type="text"></td>
        </tr>
        <tr>
          <td><label for="emailParent">Correo Electrónico:</label></td>
          <td><label for="phoneParent">Teléfono:</label></td>
          <td><label for="mobileParent">Celular:</label></td>
        </tr>
        <tr>
          <td><input id="emailParent" formControlName="email" type="email"></td>
          <td><input id="phoneParent" formControlName="phone" type="text" [ngClass]="{'is-invalid': isFieldInvalid('parent', 'phone')}"></td>
          <td><input id="mobileParent" formControlName="mobile" type="text" [ngClass]="{'is-invalid': isFieldInvalid('parent', 'mobile')}"></td>
        </tr>
      </table>
    </div>

    <div class="section">
      <h2>III. DATOS DEL BIEN CONTRATADO</h2>
      <table class="form-table" formGroupName="productDetails">
        <tr>
          <td><label for="amountProduct">Monto Reclamado:</label></td>
          <td><label for="isProduct">Producto:</label></td>
          <td><label for="isService">Servicio:</label></td>
        </tr>
        <tr>
          <td><input id="amountProduct" formControlName="amount" type="number"></td>
          <td><input id="isProduct" formControlName="isProduct" type="checkbox" (change)="toggleProductService('isProduct')"></td>
          <td><input id="isService" formControlName="isService" type="checkbox" (change)="toggleProductService('isService')"></td>
        </tr>
        <tr>
          <td colspan="3"><label for="descriptionProduct">Descripción:</label></td>
        </tr>
        <tr>
          <td colspan="3"><textarea id="descriptionProduct" formControlName="description"  [ngClass]="{'is-invalid': isFieldInvalid('productDetails', 'description')}"></textarea></td>
        </tr>
      </table>
    </div>

    <div class="section">
      <h2>IV. DETALLE DE LA RECLAMACIÓN</h2>
      <table class="form-table" formGroupName="reclamationDetails">
        <tr>
          <td><label for="isReclamation">Reclamo:</label></td>
          <td><label for="isComplaint">Queja:</label></td>
        </tr>
        <tr>
          <td><input id="isReclamation" formControlName="isReclamation" type="checkbox" (change)="toggleCheckbox('isReclamation')"></td>
          <td><input id="isComplaint" formControlName="isComplaint" type="checkbox" (change)="toggleCheckbox('isComplaint')"></td>
        </tr>
        <tr>
          <td colspan="2"><label for="descriptionReclamation">Descripción del Reclamo o Queja:</label></td>
        </tr>
        <tr>
          <td colspan="2"><textarea id="descriptionReclamation" formControlName="description" [ngClass]="{'is-invalid': isFieldInvalid('reclamationDetails', 'description')}"></textarea></td>
        </tr>
      </table>
    </div>

    <button type="submit">Enviar</button>
  </form>

  <div class="disclaimer">
    <p>*La formulación del reclamo no impide acudir a otras vías de solución de controversias ni es requisito previo para interponer una denuncia ante el INDECOPI.</p>
    <p>*El proveedor deberá dar respuesta al reclamo en un plazo no mayor a quince (15) días hábiles.</p>
    <p>*INDECOPI está ubicado en la Calle de la Prosa No. 104, San Borja, Lima 41.</p>
    <p>*Recibirás una copia de tu Hoja de Reclamación en tu correo electrónico.</p>
    <p>*La información personal que nos proporciones a través de esta Hoja de Reclamación se sujeta a los términos de la Política de Privacidad, en la cual se contemplan los usos aplicables a la atención de reclamos.</p>
  </div>
</div>

</main>

<app-footer></app-footer>
