<app-header></app-header>

<main class="bg-[#EBEBEB] pt-[33px] pb-[92px] md:pb-[82px] px-9">
  <a routerLink="/usuario/graduados" class="inline-block mb-[26px] md:mb-9">
    <img src="../../../../assets/icons/arrow-left.png" alt="arrow-left-black" />
  </a>
  <h2
    class="text-3xl text-center sm:text-[40px] md:text-3xl mb-[60px] md:mb-[50px] font-medium"
    *ngIf="idVideo == 1"
  >
    Testimonio 1
  </h2>
  <h2
    class="text-3xl text-center sm:text-[40px] md:text-3xl mb-[60px] md:mb-[50px] font-medium"
    *ngIf="idVideo == 2"
  >
    Testimonio 2
  </h2>
  <h2
    class="text-3xl text-center sm:text-[40px] md:text-3xl mb-[60px] md:mb-[50px] font-medium"
    *ngIf="idVideo == 3"
  >
    Testimonio 3
  </h2>
  <div class="md:flex justify-center">
    <div class="md:w-3/5 relative flex justify-center">
      <video
        with
        controls
        controls
        [muted]="'muted'"
        width="100%"
        height="267px"
        autoplay
      >
        <source
          *ngIf="idVideo == 1"
          src="../../../assets/vid/CANTERA_VIDEO1.mp4"
          type="video/mp4"
        />
        <source
          *ngIf="idVideo == 2"
          src="../../../assets/vid/CANTERA_VIDEO2.mp4"
          type="video/mp4"
        />
        <source
          *ngIf="idVideo == 3"
          src="../../../assets/vid/CANTERA_VIDEO3.mp4"
          type="video/mp4"
        />
      </video>
    </div>
  </div>
</main>

<app-footer></app-footer>
