import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IReclamation} from '@app/models/IReclamation';
import { ReclamationService } from '@app/services/reclamation.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-reclamation',
  templateUrl: './reclamation.component.html',
  styleUrls: ['./reclamation.component.css']
})
export class ReclamationComponent {
  reclamationForm: FormGroup;
  currentDate: Date = new Date();

  constructor(private fb: FormBuilder,
    private reclamationService: ReclamationService) {
    this.reclamationForm = this.fb.group({
      consumer: this.fb.group({
        dni: ['', [Validators.required, Validators.pattern(/^\d{8}$/)]],
        name: ['', Validators.required],
        address: ['', Validators.required],
        email: ['', [Validators.required, Validators.email]],
        phone: ['', [Validators.pattern(/^\d{6,7}$/)]],
        mobile: ['',[Validators.pattern(/^\d{9}$/)]],
      }),
      parent: this.fb.group({
        dni: ['', Validators.pattern(/^\d{8}$/)],
        name: [''],
        address: [''],
        email: ['', [Validators.email]],
        phone: [''],
        mobile: [''],
      }),
      productDetails: this.fb.group({
        amount: [''],
        isProduct: [false],
        isService: [false],
        description: ['', Validators.required],
      }),
      reclamationDetails: this.fb.group({
        isReclamation: [false],
        isComplaint: [false],
        description: ['', Validators.required],
      }),
    });
  }

  toggleCheckbox(field: string) {
    const reclamationDetails = this.reclamationForm.get('reclamationDetails');
    if (field === 'isReclamation') {
      reclamationDetails?.get('isComplaint')?.setValue(false);
    } else if (field === 'isComplaint') {
      reclamationDetails?.get('isReclamation')?.setValue(false);
    }
  }

  toggleProductService(field: string): void {
    const control = this.reclamationForm.get('productDetails')?.get(field);
    if (control?.value) {
      const otherField = field === 'isProduct' ? 'isService' : 'isProduct';
      this.reclamationForm.get('productDetails')?.get(otherField)?.setValue(false);
    }
  }

  onSubmit() {
    if (this.reclamationForm.valid) {
      const formData = this.reclamationForm.value;

      const reclamation: IReclamation = {
        consumer: {
          dni: formData.consumer.dni,
          name: formData.consumer.name,
          address: formData.consumer.address,
          email: formData.consumer.email,
          phone: formData.consumer.phone,
          mobile: formData.consumer.mobile,
        },
        parent: formData.parent.dni ? {
          dni: formData.parent.dni,
          name: formData.parent.name,
          address: formData.parent.address,
          email: formData.parent.email,
          phone: formData.parent.phone,
          mobile: formData.parent.mobile,
        } : undefined,
        productDetails: {
          amount: formData.productDetails.amount,
          isProduct: formData.productDetails.isProduct,
          isService: formData.productDetails.isService,
          description: formData.productDetails.description,
        },
        reclamationDetails: {
          isReclamation: formData.reclamationDetails.isReclamation,
          isComplaint: formData.reclamationDetails.isComplaint,
          description: formData.reclamationDetails.description,
        },
      };

      this.reclamationService.saveClaim(reclamation).subscribe({
        next: response => {
          Swal.fire({
            title: '',
            text: 'Tu reclamación se ha enviado correctamente.',
            icon: 'success',
            confirmButtonText: 'Aceptar'
          }).then((result) => {
            location.reload();
          });
        },
        error: error => {
          Swal.fire('Hubo un error inesperado, inténtelo más tarde','','error')
          console.log(error);
        }
      });
    } else {
      this.validateAllFormFields(this.reclamationForm);
      Swal.fire('Completa o corrige los datos ingresados','','error');
    }
  }

  isFieldInvalid(group: string, field: string): boolean {
    const formGroup = this.reclamationForm.get(group) as FormGroup;
    const control = formGroup.get(field);
    return control ? control.invalid && (control.dirty || control.touched) : false;
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      } else {
        control?.markAsTouched({ onlySelf: true });
      }
    });
  }
}
