import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { Title, Meta } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
@Component({
  selector: 'app-planos',
  templateUrl: './planos.component.html',
  styleUrls: ['./planos.component.scss']
})

@HostListener('window:scroll', [])

export class PlanosComponent implements OnInit {

  constructor( 
    private router: Router,
    private http: HttpClient,
    private titleService: Title,
    private metaService: Meta
  ) { }

  ngOnInit(): void {
    this.getMetaTagsData();
    this.scrollToTop();
  }

  //metadatos de seo
  getMetaTagsData() {
    this.http.get<any>("/assets/metatags/constructor/planos.json").subscribe(res => {
      this.setPageTile(res.title)
      if(res.tags){
        this.setMetaTags(res.tags);
      }
    })
  }
  
  // set meta data
  setMetaTags(tags: any ) {
    this.metaService.addTags(tags)
  }
  // set page title
  setPageTile(title: string) {
    this.titleService.setTitle(title);
  }

  scrollToTop() { 
    (function smoothscroll() 
    { var currentScroll = document.documentElement.scrollTop || document.body.scrollTop; 
      if (currentScroll > 0) 
      {
        window.requestAnimationFrame(smoothscroll);
        window.scrollTo(0, currentScroll - (currentScroll / 5));
      }
    })();
  }

}
