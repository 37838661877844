<div class="cookies" *ngIf="showNotice">
  <div class="cookies_overlay"></div>
  <div class="cookies_content">
    <div class="page_container">
      <div class="cookies_block">
        <h3 class="cookies_title">
          Esta página web usa cookies necesarias para su funcionamiento. Para
          tener una mejor experiencia, acepta las condiciones y continúa
          navegando sin restricción alguna.
        </h3>
        <div class="cookies_tabs">
          <span
            class="tablinks"
            [ngClass]="{ active: activeTab === 'statementCookies' }"
            (click)="selectTab('statementCookies')"
            id="defaultOpen"
            >Declaración de cookies</span
          >
          <span
            class="tablinks"
            [ngClass]="{ active: activeTab === 'aboutCookies' }"
            (click)="selectTab('aboutCookies')"
            >Acerca de las cookies</span
          >
        </div>
        <div class="cookies_body">
          <div
            *ngIf="activeTab === 'statementCookies'"
            id="statementCookies"
            class="tabcontent cookies_details"
          >
            <div class="cookies-check">
              <div class="cookies-check-check">
                <label for="necesaryCookies">
                  <input
                    class="cookies-input"
                    type="checkbox"
                    checked
                    disabled
                    id="necesaryCookies"
                    name="necesarias"
                  />
                </label>
                <div class="cookies-check-tab">Necesarias</div>
              </div>
            </div>
            <div class="cookies-check">
              <div class="cookies-check-check">
                <label for="statsCookies">
                  <input
                    class="cookies-input"
                    type="checkbox"
                    checked
                    id="statsCookies"
                    name="estadisticas"
                  />
                </label>
                <div class="cookies-check-tab" (click)="toggleAccordion()">Estadísticas</div>
              </div>
              <div class="cookies-accordion" *ngIf="accordionOpen">
                <div class="cookies-check-content">
                  <p>
                    Nos ayudan a comprender cómo interactúan los visitantes con
                    nuestro Sitio Web, ayudándonos a mejorar su estructura y
                    contenido, reuniendo y proporcionando información de forma
                    anónima.
                  </p>
                  <div class="cookies-table">
                    <table>
                      <thead>
                        <tr>
                          <th>Nombre</th>
                          <th>Proveedor</th>
                          <th>Propósito</th>
                          <th>Caducidad</th>
                          <th>Tipo</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>_ga</td>
                          <td>unacem.pe</td>
                          <td>
                            Registra una identificación única que se utiliza
                            para generar datos estadísticos acerca de cómo
                            utiliza el visitante el sitio web.
                          </td>
                          <td>2 años</td>
                          <td>HTTP</td>
                        </tr>
                        <tr>
                          <td>_ga#</td>
                          <td>unacem.pe</td>
                          <td>
                            Recopila datos sobre el número de veces que un
                            usuario ha visitado el sitio web además de las
                            fechas de la primera visita y de la más reciente.
                            Utilizada por Google Analytics.
                          </td>
                          <td>2 años</td>
                          <td>HTTP</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            *ngIf="activeTab === 'aboutCookies'"
            id="aboutCookies"
            class="tabcontent cookies_description"
          >
            <p>
              Las cookies son pequeños archivos de texto que los sitios web
              almacenan para recopilar cierta información sobre su experiencia
              de navegación y sobre sus preferencias.
            </p>
            <p>
              Conoce nuestra pólitica
              <a
                href="/assets/pdf/politica-cookies-unacem.pdf"
                target="_blank"
                >aquí</a
              >
            </p>
          </div>
        </div>
        <div class="cookies_date">Actualizado al 31/05/2024</div>
        <div class="cookies_btns">
          <button id="cookiesDenied" (click)="denyCookies()" class="cookies_btn">Denegar</button>
          <button id="cookiesActive" (click)="allowCookies()" class="cookies_btn">Permitir</button>
        </div>
      </div>
    </div>
  </div>
</div>
