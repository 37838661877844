<app-header></app-header>
<div
  [style.height]="divHeight"
  class="home__sections bg-white md:flex justify-center"
>
  <iframe
    frameborder="0"
    width="100%"
    height="100%"
    allowfullscreen="true"
    webkitallowfullscreen="true"
    mozallowfullscreen="true"
    [src]="urlSafe"
  >
  </iframe>

  <!--   <section class="home__section pt-[75px] md:w-1/2">
    <div class="home__container">
      <h4
        class="text-perso text-[40px] pl-[40px] pr-[40px] pb-[40px] md:pr-[50px] md:text-[30px] md:leading-[110%] md:pb-[25px]"
      >
        <img src="../../../assets/svg/dir1.svg" alt="" /> Duración del curso
      </h4>

      <div
        style="
          margin: 10px 0px !important;
          display: flex;
          flex-direction: row;
          align-items: flex-start;
        "
        class="text-perso text-[40px] pl-[40px] pr-[40px] pb-[40px] md:pr-[50px] md:text-[30px] md:leading-[110%] md:pb-[25px]"
      >
        <div>
          <img src="../../../assets/svg/dir2.svg" />
        </div>
        <div>
          <h3 type="md" class="sc-kDDrLX eSScd">Lecciones</h3>
          <h4 class="text-black" style="font-weight: 700">0 leccion</h4>
        </div>
      </div>

      <h4
        (click)="regresaraCurso()"
        style="display: flex; cursor: pointer"
        class="text-black text-[40px] pl-[40px] pr-[40px] pb-[40px] md:pr-[50px] md:text-[30px] md:leading-[110%] md:pb-[25px]"
      >
        <img src="../../../assets/svg/flecha.svg" alt="" />
        <span style="font-size: 15px; margin-left: 10px; font-weight: 700"
          >Ver todos los cursos</span
        >
      </h4>

    </div>
  </section>
  <section
    style="width: 26%; background: rgb(250, 250, 250); border-radius: 8px"
    class="home__section pt-[75px]"
  >
    <div class="home__container">
      <button
        disabled=""
        style="background: #ff0000"
        type="button"
        color="primary"
        class="btn-certi"
      >
        <div class="aling-items">
          <img
            src="../../../assets/svg/candado.svg"
            style="margin-right: 18px"
          /><span>OBTENER CERTIFICADO</span>
        </div>
      </button>
    </div>

    <a
      style="
        display: flex;
        font-size: 15px;
        margin-left: 10px;
        padding-top: 10px;
      "
      >Recuerda que debes terminar el curso en su totalidad para realizar el
      cuestionario y obtener tu certificado.</a
    >

    <h4
      (click)="regresaraCurso()"
      style="display: flex; cursor: pointer"
      class="text-black text-[40px] pl-[20px] pr-[40px] pb-[40px] md:pr-[50px] md:text-[30px] md:leading-[110%] md:pb-[25px]"
    >
      <img src="../../../assets/svg/flecha.svg" alt="" />
      <span style="font-size: 15px; margin-left: 10px; font-weight: 700"
        >Ver todos los cursos</span
      >
    </h4>
  </section> -->
</div>
<app-footer></app-footer>
