<app-header></app-header>
<main *ngIf="mostrar" class="capacitaciones-online">
  <div class="relative">
    <div class="banner bg_capacitaciones bg-cover bg-no-repeat h-[330px] grid items-center"></div>
    <img
      class="absolute top-[30px] left-[43px]"
      src="../../../../assets/icons/arrow-left.png"
      alt="flecha"/>
    <div class="absolute title text-center text-white">
      <h6 class="text-[16px] leading-[110%] mb-[20px] block">Capacitaciones</h6>
      <h1 class="text-[44px] leading-[110%]">Cursos Online</h1>
    </div>
  </div>

  <div class="bg-white p-[50px] text-center mx-900px">
    <div
      class="bg-black w-[62px] h-[2px] mx-[0] mb-[40px] mx-auto"
      style="margin: 0 auto; margin-bottom: 30px"
    ></div>
    <p class="color-black text-[20px] leading-[160%] md:text-[15px]">
      Hola Maestro, te presentamos los cursos gratuitos que podrás realizar para
      completar tus conocimientos.
      <br />Todo a tu disposición en el horario que mejor te acomode.
    </p>
  </div>
  <div class="bg-black pb-[20px]">
    <div class="contenedor content_form_search lg:flex">
      <!-- <div class="descripcion">
        <p>
          Maestro, en esta sección encontrarás todas las capacitaciones en vivo
          que hemos venido realizando con nuestras marcas de cemento. Podrás
          verlas cuando lo necesites y repetirlas o compartirlas de manera
          sencilla
        </p>
      </div> -->
      <img
        class="imagen top-[-5px] sm:top-[-15px] mb-[10px] lg:w-full lg:top-0 lg:mb-0"
        src="../../../../assets/img/cantera.png"
        alt="cantera"/>
      <div
        class="lupa px-[40px] sm:px-[115px] lg:px-0 lg:w-[350px] lg:flex lg:items-end lg:ml-[-60px]">
        <input
          class="text-[#13120F] rounded-[10px] bg-white w-full py-[25px] px-[30px] text-[20px] md:text-lg outline-none
           placeholder:text-[#13120F] lg:py-[17px] lg:px-[21px] lg:leading-[18px] lg:text-[14px]"
          placeholder="Buscar curso"
          [(ngModel)]="searchValue"
          (ngModelChange)="onSearchChange(searchValue)"/>
      </div>
    </div>

    <!-- <h3 class="fecha"
      style="
        color: white;
        padding-left: 40px;
        padding-top: 60px;
        padding-bottom: 20px;
        display: none;">
      PRÓXIMO CURSO 20 AGO 2022
    </h3>
    
    <div class="contenedor" style="display: none">
      <div class="imagen">
        <img src="../../../assets/img/destacado 1.png" alt="" />
      </div>
      <div class="titulo">
        <h4>Lorem ipsum dolor sit amet</h4>
      </div>
      <div class="texto">
        <p class="texto">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Quibusdam
          quia laborum provident dolorum, perferendis volam, nihil nisi ex!
        </p>
      </div>
      <div class="boton">
        <a
          href="http://localhost:8000/"
          class="rounded-[10px] flex py-[11px] items-center justify-center text-[20px] text-[#FF0000] border-[#FF0000]
           border-[1px] hover:bg-[#FF0000] hover:text-white md:text-[14px]">
          Inscríbete aquí
          <i class="fa fa-angle-right ml-[20px]"></i>
        </a>
      </div>
    </div> -->

    <!-- LISTA DE CURSOS -->
    <app-lista-cursos [items]="cursosData"></app-lista-cursos>
  </div>
</main>
<app-footer></app-footer>
