import { Component, HostListener, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SwitchService } from '@app/services/switch.service';
import { CoursesService } from '@app/services/courses.service';
import { ICursosItem } from './ICurso-item.metadata';
import jwt_decode from 'jwt-decode';

@Component({
  selector: 'app-lista-cursos',
  templateUrl: './lista-cursos.component.html',
  styleUrls: ['./lista-cursos.component.scss'],
})

@HostListener('window:scroll', [])

export class ListaCursosComponent implements OnInit {
  @Input() items: ICursosItem[] = [];

  paginaActual: number = 1;
  numItemsAmostrar: number = 6;
  token = localStorage.getItem('token');
  dataCurso: any;
  dataCurso2: any;

  totalDePaginas: number = 0;
  img: Array<string> = [
    '../../../assets/img/cursos/1.png',
    '../../../assets/img/cursos/2.png',
    '../../../assets/img/cursos/3.png',
    '../../../assets/img/cursos/4.png',
    '../../../assets/img/cursos/5.png',
    '../../../assets/img/cursos/6.png'
  ];

  constructor(
    private coursesService: CoursesService,
    private router: Router,
    private switchService: SwitchService
  ) {
  }

  ngOnInit(): void {
    this.coursesService.init()
    this.coursesService.getCourses().subscribe((courses) => {
      this.dataCurso = courses
    })
  }

  scrollToTop() {
    (function smoothscroll() {
      var currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
      if (currentScroll > 0) {
        window.requestAnimationFrame(smoothscroll);
        window.scrollTo(0, currentScroll - (currentScroll / 5));
      }
    })();
  }

  scrollToElement( ): void {
    const element = document.getElementById( 'content-list' );
    if (element) {
      setTimeout(function(){
        element.scrollIntoView({ behavior: 'smooth' });
      }, 50)
    }
  };

  private getDecodedToken(token: string): any {
    try {
      return jwt_decode(token);
    } catch(Error) {
      return null;
    }
  }

  entraCurso(id: any) {
    const token = localStorage.getItem('token') || "";
    const tokenInfo = this.getDecodedToken(token)
    if (tokenInfo !== null) {
      const expireDate = tokenInfo.exp  * 1000;
      const now = Date.now()
      if (now >= expireDate) {
        this.switchService.$modalLogin.emit(true)
      } else  {
        this.router.navigate([`usuario/cursos-view/${token}/${id}`]);
        this.scrollToTop()
      }
    } else {
      this.switchService.$modalLogin.emit(true)
    }
  }
  
  addZero(value: number): string {
    return value < 10 ? `0${value}` : `${value}`;
  }

  formatDate( value: string, format?: string){
    const dateValue = new Date(value);

    const day = this.addZero(dateValue.getDate());
    const month = this.addZero(dateValue.getMonth() + 1);
    const year = dateValue.getFullYear();
    const hours = this.addZero(dateValue.getHours());
    const minutes = this.addZero(dateValue.getMinutes());
    const seconds = this.addZero(dateValue.getSeconds());

    const months = ["ENE", "FEB", "MAR", "ABR", "MAY", "JUN", "JUL", "AGO", "SEP", "OCT", "NOV", "DIC"];

    return `${day} ${months[parseInt(month) - 1]} ${format ? format : year}`;
  }
}
