import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EventEmitter, Injectable, Output } from '@angular/core';
import { IUsuario, IUsuarioU ,IPostula} from '@app/models/IUsuario';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UsuarioService {
  httpHeader = {
    headers: new HttpHeaders({
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
    }),
  };

  constructor(private http: HttpClient) {}
  @Output() emitirPerfil = new EventEmitter<any>();

  getUser(id: any): Observable<any> {
    let headers = new HttpHeaders({
      'Access-Control-Allow-Origin': '*',
      'Content-type': 'application/json',
      'Authorization' : id
    });

    return this.http.get(
      `${environment.api_url}user/get_perfil`,
      { headers: headers }
    );
  }

  getPostula(id: any): Observable<any> {
    let headers = new HttpHeaders({
      'Access-Control-Allow-Origin': '*',
      'Content-type': 'application/json',
      'Authorization' : id
    });
    return this.http.get(
      `${environment.api_url}user/get_postula`,
      { headers: headers }
    );
  }

  updateUser(usuario: IUsuarioU) {
    const params: any = usuario;
    const token: any = localStorage.getItem('token');
    let headers = new HttpHeaders({
      'Access-Control-Allow-Origin': '*',
      'Content-type': 'application/json',
      'Authorization' : token
    });
    let body = {
      nombres :usuario.nombre,
      apellidos :usuario.apellidos,
      profesion :usuario.ciudad,
      celular :usuario.celular,
      telefono :usuario.telefono,
      email : usuario.email, 
      password :usuario.password,
    }
    return this.http.put<IUsuarioU>(
      `${environment.api_url}user/update_perfil`,
      body,
      {headers:headers}
    );
  }

  removeUsuario() {
    window.sessionStorage.removeItem('ulg');
  }
  
  postular(usuario:IPostula) {
    const params: any = usuario;
    //console.log("postulaService",usuario);
    let headers = new HttpHeaders({
      'Access-Control-Allow-Origin': '*',
      'Content-type': 'application/json',
    });
    let body ={
      nombres : usuario.nombres,
      apellidos : usuario.apellidos,
      email : usuario.email,
      dni : usuario.dni,
      celular : usuario.celular,
      telefono : usuario.telefono,
      ocupacion : usuario.ocupacion,
      ocupacion_otros : usuario.ocupacion_otros,
      experiencia : usuario.experiencia,
      aprobado : usuario.aprobado,
      idUsuario : usuario.idUsuario,
    }
    return this.http.post<IPostula>( `${environment.api_url}user/new_postula`, body, this.httpHeader )
  }


}
