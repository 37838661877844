<body>
  <main class="capacitaciones-online">
    <div class="bg-black pb-[70px] pt-[70px] md:pb-[50px] md:pt-[70px]">
      <h4 class="text-[30px] text-white text-center pb-[30px] md:text-left md:px-[40px]">
        Otros cursos que te podrian interesar
      </h4>
      <div class="content-list">
        <ul class="list-course px-[40px] pt-[30px] grid grid-cols-1 sm:grid-cols-2 gap-[35px] lg:grid-cols-3 lg:gap-[50px]" >
          <li
            class="item-course"
            *ngFor="let curso of data | paginate : { itemsPerPage: numItemsAmostrar, currentPage: paginaActual } "
            >
            <div class="md:flex md:justify-between md:items-end md:mb-[15px]">
              <div class="date-course text-[#C4C4C4] text-[14px] leading-[160%] md:text-[12px] md:order-last" >
                {{ data.fechaCreacion | date : "dd M YYY" }}
              </div>
              <div class="title-course text-[18px] text-white mt-[10px] mb-[20px] uppercase md:order-first md:max-w-[228px] md:mt-0 md:mb-0" >
                {{ curso.nombre }}
              </div>
            </div>
            <div class="relative">
              <!-- <div
                *ngIf="curso.thumbnailRutaPublica"
                (click)="entraCurso(curso.idCurso)"
                [ngStyle]="{ 'background-image': 'url(' + curso.thumbnailRutaPublica + ')', filter: 'brightness(50%)' }"
                class="img-course bg-cover bg-no-repeat min-h-[185px] relative"></div> -->
                
                <div *ngIf="curso.thumbnailRutaPublica" class="relative filter-cover">
                  <img src="{{curso.thumbnailRutaPublica }}" alt="{{ curso.nombre }}" class="w-full">
                </div>
              <!-- <div
                *ngIf="!curso.thumbnailRutaPublica"
                (click)="entraCurso(curso.idCurso)"
                [ngStyle]="{ 'background-image': 'url()', filter: 'brightness(50%)' }"
                class="img-course bg-cover bg-no-repeat min-h-[185px] relative"></div> -->

              <a (click)="entraCurso(curso.idCurso)" class="absolute right-[20px] bottom-[15px]" >
                <img class="w-[58px] md:w-[38px]" src="../../../assets/icons/play.png" alt="play" />
              </a>
            </div>
            <!-- <div class="description-course text-[18px] text-white leading-[160%] mt-[20px] text-justify line-clamp-4 md:text-[14px] color">
                            {{curso.descripcion}}</div>
                        <div class="inscription mt-[30px]">
                            <a href="#" class="btn-inscription-course rounded-[10px] flex max-w-[225px] py-[11px] items-center justify-center
                            text-[20px] text-[#FF0000] border-[#FF0000] border-[1px] hover:bg-[#FF0000] hover:text-white md:text-[14px] md:max-w-[175px]">
                            Inscríbete aquí <i class="fa fa-angle-right ml-[20px]"></i></a>
                        </div> -->

            <div class="description-course text-[18px] text-white leading-[160%] mt-[20px] text-justify line-clamp-4 md:text-[14px] color" >
              <app-show-more [curso]="curso"></app-show-more>
            </div>
            <div class="inscription mt-[30px]" (click)="entraCurso(curso.idCurso)" style="cursor: pointer" >
              <a
                *ngIf="token == null || token == undefined"
                class="btn-inscription-course rounded-[10px] flex max-w-[225px] py-[11px] items-center justify-center text-[20px] text-[#FF0000] border-[#FF0000] border-[1px] hover:bg-[#FF0000] hover:text-white md:text-[14px] md:max-w-[175px]">
                Inscríbete aquí
                <i class="fa fa-angle-right ml-[20px]"></i>
              </a>
            </div>
          </li>

          <!-- <li class="item-course">
                        <div class="md:flex md:justify-between md:items-end md:mb-[15px]">
                            <div class="date-course text-[#C4C4C4] text-[14px] leading-[160%] md:text-[12px] md:order-last">12 AGO 2022</div>
                            <div class="title-course text-[18px] text-white mt-[10px] mb-[20px] uppercase md:order-first md:max-w-[228px] md:mt-0 md:mb-0">
                                Seguridad durante la construcción parte I</div>
                        </div>
                        <div class="relative">
                        <div class="img-course bg_otros_cursos bg-cover bg-no-repeat min-h-[185px] relative"></div>
                            <a href="#" class="absolute right-[20px] bottom-[15px]">
                                <img class="w-[58px] md:w-[38px]" src="../../../assets/icons/play.png" alt="play">
                            </a>
                        </div>
                        <div class="description-course text-[18px] text-white leading-[160%] mt-[20px] text-justify line-clamp-4 md:text-[14px] color">
                            Conoce la normativa vigente con los nuevos protocolos sanitarios que deben cumplirse de manera obligatoria
                            en todos los presupuesto de construcción es un documento que resume el cálculo anticipado.</div>
                        <div class="inscription mt-[30px]">
                            <a href="#" class="btn-inscription-course rounded-[10px] flex max-w-[225px] py-[11px] items-center justify-center
                            text-[20px] text-[#FF0000] border-[#FF0000] border-[1px] hover:bg-[#FF0000] hover:text-white md:text-[14px] md:max-w-[175px]">
                            Inscríbete aquí <i class="fa fa-angle-right ml-[20px]"></i></a>
                        </div>
                    </li>
                    <li class="item-course">
                        <div class="md:flex md:justify-between md:items-end md:mb-[15px]">
                            <div class="date-course text-[#C4C4C4] text-[14px] leading-[160%] md:text-[12px] md:order-last">12 AGO 2022</div>
                            <div class="title-course text-[18px] text-white mt-[10px] mb-[20px] uppercase md:order-first md:max-w-[228px] md:mt-0 md:mb-0">
                                Seguridad durante la construcción parte I</div>
                        </div>
                        <div class="relative">
                        <div class="img-course bg_otros_cursos bg-cover bg-no-repeat min-h-[185px] relative"></div>
                            <a href="#" class="absolute right-[20px] bottom-[15px]">
                                <img class="w-[58px] md:w-[38px]" src="../../../assets/icons/play.png" alt="play">
                            </a>
                        </div>
                        <div class="description-course text-[18px] text-white leading-[160%] mt-[20px] text-justify line-clamp-4 md:text-[14px] color">
                            Conoce la normativa vigente con los nuevos protocolos sanitarios que deben cumplirse de manera obligatoria
                            en todos los presupuesto de construcción es un documento que resume el cálculo anticipado.</div>
                        <div class="inscription mt-[30px]">
                            <a href="#" class="btn-inscription-course rounded-[10px] flex max-w-[225px] py-[11px] items-center justify-center
                            text-[20px] text-[#FF0000] border-[#FF0000] border-[1px] hover:bg-[#FF0000] hover:text-white md:text-[14px] md:max-w-[175px]">
                            Inscríbete aquí <i class="fa fa-angle-right ml-[20px]"></i></a>
                        </div>
                    </li> -->
        </ul>

        <div class="flex px-[40px] py-[40px] justify-between items-center md:max-w-[330px] md:mx-auto" >
          <pagination-controls (pageChange)="paginaActual = $event" previousLabel="" nextLabel="" > </pagination-controls>
        </div>
      </div>
    </div>
  </main>
</body>
