<app-header></app-header>
<section class="contenedor" style="background: #EBEBEB; position: relative;">
  <a [routerLink]="['/constructor/herramientas']" style="cursor: pointer; position: absolute; top: 30px; left: 27px;">
    <img src="../../../../assets/icons/arrow-left-black.png">
  </a>
  <div class="cuerpo">
    <div class="position-relative">
      <h1 class="titulo">Planos casas</h1>
      <p class="parrafo-1" style="font-weight: 500; padding-top: 20px;">
        Con el objetivo de mejorar la calidad de las construcciones y combatir la construcción informal en el Perú, 
        ponemos a tu alcance 3 planos de casas tipo con formatos de 1, 2 y 3 pisos*, los cuales tienen la característica
        de ser sismo resistentes. En cada uno de estos planos encontrarás la distribución de los ambientes, los detalles
        de construcción de cada parte del inmueble y las especificaciones técnicas a considerar.
      </p>
    </div>
  </div>
  
  <div class="opciones" style="background: white;">
    <a [routerLink]="['/constructor/herramientas/planos/casas-de-1-piso']" target="_blank" class="piso-1">
      <img class="imagen" src="../../../../assets/svg/plano 1.svg" alt=""/>
      <p class="texto">Plano de casas de 1 piso</p>
      <div class="icono">
        <i class=" fa-solid fa-angle-right"></i>
      </div>
    </a>
    <a [routerLink]="['/constructor/herramientas/planos/casas-de-2-piso']" target="_blank" class="piso-2">
      <img class="imagen" src="../../../../assets/svg/plano 1.svg" alt=""/>
      <p class="texto">Plano de casas de 2 piso</p>
      <div class="icono">
        <i class="fa-solid fa-angle-right"></i>
      </div>
    </a>
    <a [routerLink]="['/constructor/herramientas/planos/casas-de-3-piso']" target="_blank" class="piso-3">
      <img class="imagen" src="../../../../assets/svg/plano 1.svg" alt=""/>
      <p class="texto">Plano de casas de 3 piso</p>
      <div class="icono">
        <i class="fa-solid fa-angle-right"></i>
      </div>
    </a>
    <p class="parrafo-2" style="font-weight: 500;">
      *Los planos son referenciales, recuerda que es importante consultar con un ingeniero civil,  ya que deben evaluar previamente las condiciones y características del terreno a construir.
    </p>
  </div>
</section>
<app-herramientas-options></app-herramientas-options>
<app-footer></app-footer>