import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EventEmitter, Injectable, Output } from '@angular/core';
import { IReclamation} from '@app/models/IReclamation';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})

export class ReclamationService {
  httpHeader = {
    headers: new HttpHeaders({
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
    }),
  };

  constructor(private http: HttpClient) {}
  @Output() emitirPerfil = new EventEmitter<any>();

  saveClaim(reclamation:IReclamation) {
    let body ={
      consumer_dni : reclamation.consumer.dni,
      consumer_name : reclamation.consumer.name,
      consumer_address : reclamation.consumer.address,
      consumer_email : reclamation.consumer.email,
      consumer_phone : reclamation.consumer.phone,
      consumer_mobile : reclamation.consumer.mobile,
      parent_dni : reclamation.parent?.dni,
      parent_name : reclamation.parent?.name,
      parent_address : reclamation.parent?.address,
      parent_email : reclamation.parent?.email,
      parent_phone : reclamation.parent?.phone,
      parent_mobile : reclamation.parent?.mobile,
      product_amount : reclamation.productDetails.amount,
      product_isproduct : reclamation.productDetails.isProduct,
      product_isservice : reclamation.productDetails.isService,
      reclamation_description : reclamation.reclamationDetails.description,
      reclamation_iscomplaint : reclamation.reclamationDetails.isReclamation,
      reclamation_isreclamation : reclamation.reclamationDetails.isComplaint,
    }
    return this.http.post<IReclamation>( `${environment.api_url}reclamation/new`, body, this.httpHeader )
  }
}
