import { Component, Input, OnInit } from '@angular/core';
import { CURSOS_DATA_ITEMS } from '@app/components/lista-cursos/curso.cons';
import { ICursosItem } from '@app/components/lista-cursos/ICurso-item.metadata';
import { SwitchService } from '@app/services/switch.service';
import { CoursesService } from '@app/services/courses.service';
import { Title, Meta } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-cursos-online',
  templateUrl: './cursos-online.component.html',
  styleUrls: ['./cursos-online.component.scss']
})
export class CursosOnlineComponent implements OnInit {

  mostrar: boolean = true;
  searchValue:string = "";
  public cursosData: ICursosItem[] = CURSOS_DATA_ITEMS;
  
  constructor(
    private coursesService: CoursesService,
    private switchService: SwitchService,
    private http: HttpClient,
    private titleService: Title,
    private metaService: Meta
    ) { }

  ngOnInit(): void {
    this.getMetaTagsData();
    this.switchService.$nav.subscribe((valor)=>{this.mostrar = valor})
    console.log(this.mostrar);
  }

   //metadatos de seo
   getMetaTagsData() {
    this.http.get<any>("/assets/metatags/cursos.json").subscribe(res => {
      this.setPageTile(res.title)
      if(res.tags){
        this.setMetaTags(res.tags);
      }
    })
  }
  
  // set meta data
  setMetaTags(tags: any ) {
    this.metaService.addTags(tags)
  }
  
  // set page title
  setPageTile(title: string) {
    this.titleService.setTitle(title);
  }


  onSearchChange(newValue: string): void {
    this.searchValue = newValue
    this.coursesService.filterCourses(newValue);
  }

}
