import { Component, OnInit } from '@angular/core';
import { GRADUADOS_DATA_ITEMS } from '@app/components/lista-graduados/graduados.cons';
import { IGraduados } from '@app/components/lista-graduados/graduados-item.medatada';
import { SwitchService } from '@app/services/switch.service';

@Component({
  selector: 'app-videos-graduados',
  templateUrl: './videos-graduados.component.html',
  styleUrls: ['./videos-graduados.component.scss']
})
export class VideosGraduadosComponent implements OnInit {

  mostrar: boolean = true;
  live: boolean = true;

  public graduadosData: IGraduados[] = GRADUADOS_DATA_ITEMS;

  constructor(private switchService: SwitchService) { }

  ngOnInit(): void {
    this.switchService.$nav.subscribe((valor)=>{this.mostrar = valor})
  }
}
