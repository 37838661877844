<div class="msection position-relative bg-gray-200 min-h-screen">
  <app-header></app-header>
  <main class="relative ">
    <div class="container mx-auto py-16">
      <div class="relative form-resset mx-auto w-full bg-white">
        <div class="relative">
          <div class="relative text-center px-8 py-6 bg-black text-white">
            <h4 class="m-0 text-2xl">Recupera tu contraseña</h4>
          </div>
          <div class="relative text-center py-8 px-4">
            <div class="w-5/6 m-auto">
              <div *ngIf="user_key; else noKeyMessage">
  
                <p>Crea una nueva contraseña para poder ingresar a tu cuenta.</p>
  
                <form class="mt-8" [formGroup]="formResspassword" (ngSubmit)="onSubmit()">
                  <div class="inputs text-start">
                    <div class="mb-6 md:mb-[14px] relative">
                      <label for="password" class="text-xl sm:text-[28px] md:text-[15px]">Nueva contraseña</label>
                      <input 
                        formControlName="password1" 
                        type="password" 
                        class="rounded-[10px] bg-gray-300 w-full p-3 text-xl sm:text-[30px] md:text-lg outline-none mt-2 form-control" 
                        [ngClass]="{ 'error-class': formResspassword.controls['password1'].invalid && (formResspassword.controls['password1'].dirty || formResspassword.controls['password1'].touched), 'valid-class': formResspassword.controls['password1'].valid }"
                        required />
  
                      <div *ngIf=" 
                            formResspassword.controls['password1'].value && 
                            formResspassword.controls['password1'].invalid &&
                            ( 
                              formResspassword.controls['password1'].errors?.['minlength'] === true ||
                              formResspassword.controls['password1'].errors?.['mayusError'] === true ||
                              formResspassword.controls['password1'].errors?.['minusError'] === true ||
                              formResspassword.controls['password1'].errors?.['numberError'] === true ||
                              formResspassword.controls['password1'].errors?.['symbolError'] === true
                            )
                            "  class="errors" >
                        <p>Tu nueva contraseña debe cumplir con lo siguiente:</p>
                        <ul>
                          <li> 
                            <span
                            [ngClass]="{ 'error': formResspassword.controls['password1'].errors?.['minlength'] }"
                            ></span>
                            <span>Mínimo 8 caracteres</span>
                          </li>
                          <li> 
                            <span
                            [ngClass]="{ 'error': formResspassword.controls['password1'].errors?.['mayusError'] }"
                            ></span>
                            <span>Una mayúscula</span>
                          </li>
                          <li> 
                            <span
                            [ngClass]="{ 'error': formResspassword.controls['password1'].errors?.['minusError'] }"
                            ></span>
                            <span>Una minúscula</span>
                          </li>
                          <li> 
                            <span
                            [ngClass]="{ 'error': formResspassword.controls['password1'].errors?.['numberError'] }"
                            ></span>
                            <span>Un número</span>
                          </li>
                          <!-- <li> 
                            <span
                            [ngClass]="{ 'error': formResspassword.controls['password1'].errors?.['symbolError'] }"
                            ></span>
                            <span>Un símbolo</span>
                          </li> -->
                        </ul>
                      </div>
                    </div>
  
                    <div class="mb-6 md:mb-[14px]">
                      <label for="password" class="text-xl sm:text-[28px] md:text-[15px]">Repite tu nueva contraseña</label>
                      <input 
                        formControlName="password2" 
                        type="password" 
                        class="rounded-[10px] bg-gray-300 w-full p-3 text-xl sm:text-[30px] md:text-lg outline-none mt-2 form-control" 
                        [ngClass]="{ 'error-class': formResspassword.controls['password2'].invalid && (formResspassword.controls['password2'].dirty || formResspassword.controls['password2'].touched) , 'valid-class': formResspassword.controls['password2'].valid }"
                        required 
                      />
                      <div *ngIf="formResspassword.controls['password2'].invalid && ( formResspassword.controls['password2'].dirty || formResspassword.controls['password2'].touched ) " class="error" >
                        <p >Tu contraseña no coincide, vuelve a escribirla.</p>
                      </div>
                    </div>
  
                    <div class="mb-6 md:mb-[14px]">
                      <button type="submit" [disabled]="formResspassword.pristine || formResspassword.invalid" class="boton-registro mt-[76px] md:mt-[30px] text-center text-white text-2xl sm:text-3xl md:text-base w-full rounded-[10px] py-3 md:border-2 md:bg-white md:py-2 md:w-[240px] md:mx-auto block font-medium btn-send">
                        <span>Confirmar</span>
                      </button>
                    </div>
                    <!-- <p>Form value: {{ formResspassword.value | json }}</p>
                    <p>Form value: {{ formResspassword.controls['password1'].errors | json }}</p> -->
                    
                  </div>
                </form>
              </div>
  
              <ng-template #noKeyMessage>
                <p>Debes proporcionar una url valida</p>
              </ng-template>
  
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
  <app-footer></app-footer>
</div>