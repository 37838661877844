import { Component, HostListener, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IFacebookLive } from './IFacebook-live-item.medatada';

@Component({
  selector: 'app-lista-facebook-live',
  templateUrl: './lista-facebook-live.component.html',
  styleUrls: ['./lista-facebook-live.component.scss'],
})

@HostListener('window:scroll', [])

export class ListaFacebookLiveComponent implements OnInit {
  @Input() items: IFacebookLive[] = [];

  paginaActual: number = 1;
  numItemsAmostrar: number = 6;

  totalDePaginas: number = 0;

 

  constructor(private router: Router) {}

  ngOnInit(): void {
    
  }

  openLive(){
    this.router.navigate(['/usuario/facebook-live-open']);
    this.scrollToTop();
  }

  scrollToTop() { 
    (function smoothscroll() 
    { var currentScroll = document.documentElement.scrollTop || document.body.scrollTop; 
      if (currentScroll > 0) 
      {
        window.requestAnimationFrame(smoothscroll);
        window.scrollTo(0, currentScroll - (currentScroll / 5));
      }
    })();
  }

}
