import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-videos-graduados-open',
  templateUrl: './videos-graduados-open.component.html',
  styleUrls: ['./videos-graduados-open.component.scss']
})
export class VideosGraduadosOpenComponent implements OnInit {

  idVideo: any;

  constructor(private route: ActivatedRoute) { 
    this.idVideo = this.route.snapshot.paramMap.get('videoid');

  }

  ngOnInit(): void {
  }

}
