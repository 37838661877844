<app-header></app-header>
<main *ngIf="mostrar">
    <div class="relative">
        <div class="banner bg_infografia bg-cover bg-no-repeat h-[330px] grid items-center">   
        </div>
        <img class="absolute top-[30px] left-[43px]" src="../../../../assets/icons/arrow-left.png" alt="flecha">
        <div class="absolute title text-center text-white">
            <h6 class="text-[16px] leading-[110%] mb-[20px] block">Programa de Especializaci&oacute;n</h6>
            <h1 class="text-[44px] leading-[110%]">Estructura del programa</h1>
        </div>
    </div>

    <!-- Modulo 1 -->
    <div class="contenedor" style="background: #EBEBEB;">
        <div class="modulos">
            <div class="header" style="background: black;">
                <p class="nro-modulo" style="color: red;">MÓDULO 1:</p>
                <p class="pregunta" style="color: white;">¿Cómo interpretar planos de arquitectura?</p>
                <p class="duracion" style="color: red;">Duración: 16 horas | 3 semanas</p>
            </div>
            <div class="body" style="background: white;">
                <div class="tema" style="font-weight: 600; padding-bottom: 15px;">
                    Tema 1 <br>Escalas y distribución de ambientes. 
                </div>
                <div class="curso" style="font-weight: 500;">
                    Curso 01: Interpretación de planos de arquitectura para la vivienda en planta.<br>
                    Curso 02: Interpretación de líneas y simbología de planos en planta.
                </div>
            </div>
            <div class="body" style="background: white;">
                <div class="tema" style="font-weight: 600; padding-bottom: 15px;">
                    Tema 2 <br>Interpretación de planos de arquitectura para vivienda en corte y elevación. 
                </div>
                <div class="curso" style="font-weight: 500;">
                    Curso 01: Interpretación de líneas y simbología de planos de corte y elevación. 
                </div>
            </div>
        </div>

        <!-- Modulo 2 -->
        <div class="modulos" style="padding-top: 50px;">
            <div class="header" style="background: black;">
                <p class="nro-modulo" style="color: red;">MÓDULO 2:</p>
                <p class="pregunta" style="color: white;">¿Cómo interpretar planos de estructuras para viviendas?</p>
                <p class="duracion" style="color: red;">Duración: 16 horas | 3 semanas</p>
            </div>
            <div class="body" style="background: white;">
                <div class="tema" style="font-weight: 600; padding-bottom: 15px;">
                    Tema 1 <br>Interpretación de planos de detalles de suelos, cimentación y columnas. 
                </div>
                <div class="curso" style="font-weight: 500;">
                    Curso 01: Interpretación de detalles del suelo en planos estructurales.<br> 
                    Curso 02: Interpretación de planos de detalle de cimentación.<br>
                    Curso 03: Interpretación de planos de detalles de columnas.
                </div>
            </div>
            <div class="body" style="background: white;">
                <div class="tema" style="font-weight: 600; padding-bottom: 15px;">
                    Tema 2 <br>Interpretación de planos de detalle de vigas, losa aligerada y escaleras. 
                </div>
                <div class="curso" style="font-weight: 500;">
                    Curso 01: Interpretación de planos de detalle de vigas.<br> 
                    Curso 02: Identificación de la configuración de la losa aligerada y escaleras.
                </div>
            </div>
        </div>

        <!-- Modulo 3 -->
        <div class="modulos" style="padding-top: 50px;">
            <div class="header" style="background: black;">
                <p class="nro-modulo" style="color: red;">MÓDULO 3:</p>
                <p class="pregunta" style="color: white;">Configuración estructural de una vivienda de albañileria confinada.</p>
                <p class="duracion" style="color: red;">Duración: 16 horas | 3 semanas</p>
            </div>
            <div class="body" style="background: white;">
                <div class="tema" style="font-weight: 600; padding-bottom: 15px;">
                    Tema 1 <br>Configuración estructural de una vivienda de albañileria confinada. 
                </div>
                <div class="curso" style="font-weight: 500;">
                    Curso 01: Configuración de sistemas estructurales,según el RNE, EO70.<br> 
                    Curso 02: Configuracion estructural.
                </div>
            </div>
            <div class="body" style="background: white;">
                <div class="tema" style="font-weight: 600; padding-bottom: 15px;">
                    Tema 2 <br>Suelos. 
                </div>
                <div class="curso" style="font-weight: 500;">
                    Curso 01: Los suelos según el RNE.<br> 
                    Curso 02: Perfiles de suelos a través del mapa de microzonificación sistema de Lima.<br>
                    Curso 03: Interpretación de detalles de suelo en planos estructurales.<br> 
                    Curso 04: Estudio de suelos.
                </div>
            </div>
            <div class="body" style="background: white;">
                <div class="tema" style="font-weight: 600; padding-bottom: 15px;">
                    Tema 3 <br>Cimentación de concreto simple y concreto armado. 
                </div>
                <div class="curso" style="font-weight: 500;">
                    Curso 01: Tipos de cimentación  superficial con concreto ciclópeo.<br> 
                    Curso 02: Tipos de cimentación superficial con concreto armado.
                </div>
            </div>
            <div class="body" style="background: white;">
                <div class="tema" style="font-weight: 600; padding-bottom: 15px;">
                    Tema 4 <br>Construcción de muros de albañileria confinada. 
                </div>
                <div class="curso" style="font-weight: 500;">
                    Curso 01: Muros portantes y no portantes, según el RNE.<br> 
                    Curso 02: Mortero para pegado de ladrillos.<br>
                    Curso 03: Tipos de confinamiento de muros.<br>
                    Curso 04: Recomendaciones generales.
                </div>
            </div>
        </div>

        <!-- Modulo 4 -->
        <div class="modulos" style="padding-top: 50px;">
            <div class="header" style="background: black;">
                <p class="nro-modulo" style="color: red;">MÓDULO 4:</p>
                <p class="pregunta" style="color: white;">¿Cómo hacer correctas armaduras de columnas y vigas?.</p>
                <p class="duracion" style="color: red;">Duración: 16 horas | 3 semanas</p>
            </div>
            <div class="body" style="background: white;">
                <div class="tema" style="font-weight: 600; padding-bottom: 15px;">
                    Tema 1 <br>Armadura del acero corrugado para cimientación y columnas. 
                </div>
                <div class="curso" style="font-weight: 500;">
                    Curso 01: Concepto propiedades y características del acero corrugado.<br> 
                    Curso 02: Consideraciones el espaciamiento y doblado para el acero de refuerzo.<br>
                    Curso 03: Proceso constructivo de cimientación respecto al acero.<br>
                    Curso 04: Conceptos, tipos y componentes, según el RNE EO60.<br>
                    Curso 05: El acero de refuerzo longitudinal.<br>
                    Curso 06: El acero de refuerzo transversal (estribos).<br>
                    Curso 07: Zona de confinamiento y empalmes por traslapes.<br>
                    Curso 08: Empalmes por traslapes.
                </div>
            </div>
            <div class="body" style="background: white;">
                <div class="tema" style="font-weight: 600; padding-bottom: 15px;">
                    Tema 2 <br>Armadura de acero corrugado. 
                </div>
                <div class="curso" style="font-weight: 500;">
                    Curso 01: Armadura para vigas y losas aligeradas.<br> 
                    Curso 02: Concepto, propiedades, tipos y componentes, según RNE EO60.<br>
                    Curso 03: El acero longitudinal.<br> 
                    Curso 04: El acero de refuerzo trasnversal (Estribos).<br>
                    Curso 05: Zona de confinamiento.<br>
                    Curso 06: Empalmes por traslapes.
                </div>
            </div>
            <div class="body" style="background: white;">
                <div class="tema" style="font-weight: 600; padding-bottom: 15px;">
                    Tema 3 <br>Armadura de acero corrugado para losa aligerada y escalera. 
                </div>
                <div class="curso" style="font-weight: 500;">
                    Curso 01: Configuración de la losa aligerada.<br> 
                    Curso 02: Configuración de escaleras.
                </div>
            </div>
        </div>

        <!-- Modulo 5 -->
        <div class="modulos" style="padding-top: 50px;">
            <div class="header" style="background: black;">
                <p class="nro-modulo" style="color: red;">MÓDULO 5:</p>
                <p class="pregunta" style="color: white;">¿Cómo preparar y colocar concreto de calidad?.</p>
                <p class="duracion" style="color: red;">Duración: 20 horas | 4 semanas</p>
            </div>
            <div class="body" style="background: white;">
                <div class="tema" style="font-weight: 600; padding-bottom: 15px;">
                    Tema 1 <br>Propiedades y materiales para el concreto. 
                </div>
                <div class="curso" style="font-weight: 500;">
                    Curso 01: El concreto.<br> 
                    Curso 02: Materiales para el concreto.
                </div>
            </div>
            <div class="body" style="background: white;">
                <div class="tema" style="font-weight: 600; padding-bottom: 15px;">
                    Tema 2 <br>Procedimiento para preparar, vaciar, vibrar y curar el concreto. 
                </div>
                <div class="curso" style="font-weight: 500;">
                    Curso 01: Proporciones para el concreto convencional.<br> 
                    Curso 02: Recomendaciones de uso de aditivos en concreto.<br>
                    Curso 03: Recomendaciones para el procedimiento del mezclado y transporte.<br> 
                    Curso 04: Recomendaciones generales.
                </div>
            </div>
            <div class="body" style="background: white;">
                <div class="tema" style="font-weight: 600; padding-bottom: 15px;">
                    Tema 3 <br>Control de calidad de concreto. 
                </div>
                <div class="curso" style="font-weight: 500;">
                    Curso 01: Control de calidad en fase fresco.<br> 
                    Curso 02: Control de calidad en concreto endurecido.
                </div>
            </div>
        </div>

        <!-- Modulo 6 -->
        <div class="modulos" style="padding-top: 50px;">
            <div class="header" style="background: black;">
                <p class="nro-modulo" style="color: red;">MÓDULO 6:</p>
                <p class="pregunta" style="color: white;">¿Cómo hacer instalaciones eléctricas de interiores para viviendas?.</p>
                <p class="duracion" style="color: red;">Duración: 16 horas | 3 semanas</p>
            </div>
            <div class="body" style="background: white;">
                <div class="tema" style="font-weight: 600; padding-bottom: 15px;">
                    Tema 1 <br>Conceptos dispositivos, accesorios y conductores eléctricos. 
                </div>
                <div class="curso" style="font-weight: 500;">
                    Curso 01: Principios de la instalación eléctrica.<br> 
                    Curso 02: Dispositivos y accesorios eléctricos.<br>
                    Curso 03: Conductores eléctricos.
                </div>
            </div>
            <div class="body" style="background: white;">
                <div class="tema" style="font-weight: 600; padding-bottom: 15px;">
                    Tema 2 <br>Interpretación de planos de instalaciones eléctricas. 
                </div>
                <div class="curso" style="font-weight: 500;">
                    Curso 01: Interpretación de planos eléctricos.
                </div>
            </div>
        </div>

    </div>
    <div class="info">
        <div class="material-programa">
            <h3 class="titulo">Material del programa</h3>
            <!-- <p class="texto">Guía didáctica del curso Recursos, semanales online Presentación multimedia semanal Sesiones de clases grabadas
                Lectura básica semanal Documentación complementaria semanal Cuestionario de evaluación online por cada tema finalizado.
            </p> -->
            <ul>
                <li>Guía didáctica del curso.</li>
                <li>Recursos semanales online.</li>
                <li>Presentación multimedia semanal.</li>
                <li>Sesiones de clases grabadas.</li>
                <li>Lectura básica semanal.</li>
                <li>Documentación complementaria semanal.</li>
                <li>Cuestionario de evaluación online por cada tema finalizado.</li>
            </ul>
        </div>
        <div class="duracion2">
            <h3 class="titulo">Duración</h3>
            <p class="texto">
                El tiempo de duración de cada módulo dependerá de las horas consignadas.
            </p>
        </div>
        <div class="evaluacion">
            <h3 class="titulo">Evaluación</h3>
            <p class="texto">
                Se realizará una evaluación online al finalizar cada módulo.
                Las notas obtenidas por cada módulo generarán el promedio ponderado final del programa.
            </p>
        </div>
        <div class="certificacion">
            <h3 class="titulo">Certificación</h3>
            <p class="texto">
                Lo obtendrás al alcanzar un promedio final aprobatorio igual o mayor a 13 como promedio ponderado final y deberás participar en las
                actividades programadas en el curso. El certificado será emitido a nombre de Unacem y el Insituto Capeco, por las 100 horas cumplidas y aprobadas.
            </p>
        </div>
    </div>
</main>

<app-footer></app-footer>
