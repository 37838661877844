import { Component, HostListener, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';

import { cursosServices } from '../lista-cursos/cursos.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-view-component',
  templateUrl: './view-component.component.html',
  styleUrls: ['./view-component.component.scss'],
})
export class ViewComponentComponent implements OnInit {
  constructor(
    private cursos: cursosServices,
    private router: Router,
    public sanitizer: DomSanitizer,
    private route: ActivatedRoute, 
    private http: HttpClient,
    private titleService: Title,
    private metaService: Meta
  ) {
    this.idCurso = this.route.snapshot.paramMap.get('cursoid');
  }

  token = localStorage.getItem('token');
  idCurso: any;
  idNuevo: any;
  urlSafe: any;
  dataCurso: any;

  divHeight = '145vh';

  @HostListener('window:popstate', ['$event'])
  onPopState(event: any) {
    this.divHeight = '145vh';
  }

  ngOnInit(): void {
    this.obtenerCurso();
    let url = `${environment.curses_url}/?token=${this.token}&idCurso=${this.idCurso}`;
    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
  
  obtenerCurso() {
    let token = localStorage.getItem('token');
    this.cursos.getCursosWeb(token, this.idCurso).subscribe(
      (data: any) => {
        console.log(data, 'AAAA');
        this.dataCurso = data.data;
        this.titleService.setTitle(`UNACEM CANTER - ${data.data.nombreCurso}`);
        this.metaService.addTag({ property: 'og:title', content: `UNACEM CANTER - ${data.data.nombreCurso}` });
        this.metaService.addTag({ name: 'description', content: data.data.descripcionCurso });
        this.metaService.addTag({ name: 'robots', content: 'index,follow' });
      },
      error => {
        console.log(error.error)
      }
    );
  }

  regresaraCurso() {
    this.router.navigate([`constructor/cursos-gratuitos`]);
  }

  obtenerIframe() {}
}
