import { Component, OnInit } from '@angular/core';
import { AuthService } from '@app/services/auth.service';

@Component({
  selector: 'app-client',
  templateUrl: './client.component.html',
  styleUrls: ['./client.component.scss'],
})
export class ClientComponent implements OnInit {
  constructor(private authService: AuthService) {}

  ngOnInit(): void {
    console.log('inicio el tempo');
    let token = localStorage.getItem('token');

    if (token != null) {
      this.authService.startTimer();
    } else {
      this.authService.stopTimer();
    }
  }
  ngOnDestroy(): void {
    this.authService.stopTimer();
  }
}
