import { Injectable } from '@angular/core';
import { ICourse } from '@app/models/ICourse';
import { cursosServices } from '@app/services/cursos.service';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class CoursesService {

    private courses = new BehaviorSubject<ICourse[]>([]);
    private courses_ = [];

    courses$: Observable<ICourse[]> = this.courses.asObservable();

    constructor(private cursos: cursosServices) { }

    init() {
        this.cursos.getCursos().subscribe((data: any) => {
            this.courses_ = data.data
            this.courses.next(data.data)
        });
    }

    filterCourses(filter?: string): void {
        let result;
        if(filter) {
            const courses = this.courses.getValue();
            result = courses.filter(course => course.descripcion.toLowerCase().includes(filter.toLowerCase()) || course.nombreCurso.toLowerCase().includes(filter.toLowerCase()))
        } else {
            result = this.courses_ ;
        }
        this.courses.next(result);
    }

    getCourses(): Observable<ICourse[]> {
        return this.courses$;
    }

}
