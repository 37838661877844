import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SwitchService } from '@app/services/switch.service';

@Component({
  selector: 'app-otros-cursos',
  templateUrl: './otros-cursos.component.html',
  styleUrls: ['./otros-cursos.component.scss'],
})
export class OtrosCursosComponent implements OnInit {
  @Input() data: any;
  paginaActual: number = 1;
  numItemsAmostrar: number = 3;
  token = localStorage.getItem('token');
  constructor(private router: Router, private switchService: SwitchService) {}

  ngOnInit(): void {}

  entraCurso(id: any) {
    let token = localStorage.getItem('token');
    if (token) {
      this.router.navigate([`usuario/cursos-view/${token}/${id}`]);
      this.scrollToTop();
    } else if (token == null) {
      //this.modalLogin = true;
      //console.log(this.modalLogin);
      this.switchService.$modalLogin.emit(true);
    }
  }

  scrollToTop() {
    (function smoothscroll() {
      var currentScroll =
        document.documentElement.scrollTop || document.body.scrollTop;
      if (currentScroll > 0) {
        window.requestAnimationFrame(smoothscroll);
        window.scrollTo(0, currentScroll - currentScroll / 5);
      }
    })();
  }
}
