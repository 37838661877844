import { Component, NgModuleRef, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from '@angular/common/http';

@Component({
  selector: 'app-pre-home',
  templateUrl: './pre-home.component.html',
  styleUrls: ['./pre-home.component.scss']
})
export class PreHomeComponent implements OnInit {

  constructor(
    private http: HttpClient,
    private titleService: Title,
    private metaService: Meta
  ) {
  }

  ngOnInit(): void {
    this.getMetaTagsData();
  }

  //metadatos de seo
  getMetaTagsData() {
    this.http.get<any>("/assets/metatags/home.json").subscribe(res => {
      this.setPageTile(res.title)
      if(res.tags){
        this.setMetaTags(res.tags);
      }
    })
  }
  
  // set meta data
  setMetaTags(tags: any ) {
    this.metaService.addTags(tags)
  }
  // set page title
  setPageTile(title: string) {
    this.titleService.setTitle(title);
  }

}
