<app-header></app-header>
<main *ngIf="mostrar" class="soluciiones-video">
    <div class="relative">
        <div class="banner bg_banner_soluciones bg-cover bg-no-repeat h-[330px] grid items-center"></div>
        <a class="absolute top-[30px] left-[43px]" href="#"><img class="" src="../../../../assets/icons/arrow-left.png" alt="flecha"></a>
        <div class="absolute title text-center text-white">
            <h6 class="text-[16px] leading-[110%] mb-[20px] block">Soluciones constructivas</h6>
            <h1 class="text-[44px] leading-[110%]">Video Tips</h1>
        </div>
    </div>    
    <div class="text-description bg-white px-[50px] pt-[50px] pb-[90px] text-center md:pt-[40px] md:pb-[70px]">
        <div class="bg-black w-[62px] h-[2px] mx-[0] mb-[40px] mx-auto md:mb-[30px]" style="margin: 0 auto; margin-bottom: 30px;"></div>
        <p class="color-black text-[20px] leading-[160%] md:text-[15px] md:px-[200px]">Ponemos a tu disposición videos con el paso a paso de las soluciones que te ayudarán a construir correctamente tu obra y que tu trabajo perdure en el tiempo.
        </p>
    </div>

    <!-- componente herramientas-options -->
    <!-- <app-herramientas-options></app-herramientas-options> -->
    
    <div class="bg-black pb-[20px]">
        <div class="content_form_search overflow-hidden lg:flex lg:pr-[50px]">
          <img
            class="relative w-full top-[-5px] sm:top-[-15px] mb-[10px] lg:w-full lg:top-0 lg:mb-0"
            src="../../../assets/img/cantera.png"
            alt="cantera"/>
          <div
            class="px-[40px] sm:px-[115px] lg:px-0 lg:w-[350px] lg:flex lg:items-end lg:ml-[-60px] lg:z-10">
          </div>
        </div>
        <div class="content-list solution">
        </div>
      </div>
     
</main>
<app-footer></app-footer>
