import { Injectable, EventEmitter, Output } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class cursosServices {
  URL_BACKEND = environment.api_url;

  constructor(private http: HttpClient) {}
  getCursos(): Observable<any> {
    let token: any = localStorage.getItem('token') || null;
    let headers = new HttpHeaders({
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
      'Authorization' : token
    });
    return this.http.get<any>(
      `${environment.api_url}user/get_cursos`,
      { headers: headers }
    );
  }

  getCursosRecomendado(): Observable<any> {
    let token: any = localStorage.getItem('token') || null;
    let headers = new HttpHeaders({
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
      'Authorization' : token
    });
    
    return this.http.get<any>(
      `${environment.api_url}user/lessons_recommended`,
      { headers: headers }
    );
  }

  getCertificados(): Observable<any> {
    let token: any = localStorage.getItem('token') || null;
    let headers = new HttpHeaders({
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
      'Authorization' : token
    });
    return this.http.get<any>(`${environment.api_url}user/certificates`, { headers: headers } );
  }

  getCursosWeb(token: any, idcurso: any): Observable<any> {
    let headers = new HttpHeaders({
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
      'Authorization' : token
    });
    let params = {
      idCurso : idcurso
    }
    return this.http.get<any>(
      `${environment.api_url}cursos/`,
      { headers: headers, params: params }
    );
  }
}
