<app-header></app-header>

<!-- Banner -->
<main *ngIf="mostrar">
  <div class="relative">
    <div
      class="banner bg_facebook_live bg-cover bg-no-repeat h-[330px] grid items-center"
    ></div>
    <div class="title text-center text-white">
      <h1 class="">Graduados</h1>
    </div>
    <img
      class="absolute top-[30px] left-[43px]"
      src="../../../../assets/icons/arrow-left.png"
      alt="flecha"
    />
  </div>

  <!-- End Banner -->

  <div class="bg-black pb-[20px]">
    <div class="contenedor content_form_search lg:flex">
      <img
        class="imagen top-[-5px] sm:top-[-15px] mb-[10px] lg:w-full lg:top-0 lg:mb-0"
        src="../../../../assets/img/cantera.png"
        alt="cantera"
      />
    </div>
    <app-lista-graduados [items]="graduadosData"></app-lista-graduados>
  </div>
</main>

<app-footer></app-footer>
