
<app-header></app-header>
<main class="bg-[#EBEBEB] pt-[33px] pb-[92px] md:pb-[82px] px-9">
      <a href="#" class="inline-block mb-[26px] md:mb-9">
        <img src="../../../../assets/icons/arrow-left.png" alt="arrow-left-black" />
      </a>
      <h2
        class="text-3xl sm:text-[40px] md:text-3xl mb-[60px] md:mb-[50px] font-medium"
      >
        Seguridad durante la construcción
      </h2>
      <div class="md:flex">
        <div class="md:w-3/5 relative">
          <div class="bg_preview_video bg-no-repeat bg-cover min-h-[460px] flex justify-end items-end p-[40px]"></div>
            <button class="absolute bottom-[40px] right-[40px] play">
              <img src="../../../../assets/icons/play-bigger.png" alt="play" />
            </button>
          
        </div>
        <div class="md:w-2/5 md:pl-10">
          <div class="space-y-6 py-16 md:pt-0 border-b border-black">
            <h3 class="font-bold text-3xl md:text-xl">
              Seguridad durante la construcción
            </h3>
            <p class="text-xl md:text-base">
              Amet cum aperiam repellat error ducimus. Impedit labore voluptate
              sapiente corrupti doloribus Maiores eaque ratione explicabo omnis
              quis. Sit veniam culpa libero explicabo ad Iusto consequatur
              voluptas perspiciatis quos voluptas?
            </p>
            <p class="text-xl md:text-base">
              Ipsum harum provident a neque error suscipit Facere error eos
              voluptate nulla facere. Officiis fuga eum iste quae provident
              mollitia Voluptatem illum blanditiis ex aliquam nemo rem Veritatis
              deserunt dicta.
            </p>
          </div>
          <button
            class="py-5 md:py-[9px] px-[43px] md:px-[18px] bg-[#ACACAC] text-white rounded-[10px] max-w-[445px] w-full mx-auto md:mx-0 block text-[30px] md:text-[14px] mt-[87px] md:mt-[33px] flex justify-between items-center md:max-w-[200px]"
          >
            Obtener Certificado
            <img
              src="../../../../assets/svg/arrow-white-btn.svg"
              alt="arrow-white"
              class="block md:hidden"
            />
            <img
              src="../../../../assets/icons/arrow-white-btn-smaller.png"
              alt="arrow-white"
              class="hidden md:block"
            />
          </button>
          <p
            class="text-center text-xl mt-[30px] md:text-left md:mt-4 md:text-xs"
          >
            Recuerda que debes terminar el curso en su totalidad para realizar
            el cuestionario y obtener tu certificado.
          </p>
        </div>
      </div>
    </main>

    <!-- componente otros-cursos -->
    <app-otros-cursos></app-otros-cursos>

    <app-footer></app-footer>



